import styled, { css } from "styled-components";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import { Auth } from "aws-amplify";
import ahoy from "ahoy.js";
import Button, { ButtonType, StyledButton } from "../Button";
import NavbarItem, { StyledNavbarItem } from "./NavbarItem";
import Flex from "../Flex";
import hexToRgb from "../hexToRgb";
import { Colors } from "../GlobalStyle";
import NavbarMenu, { NavbarMenuList } from "./NavbarMenu";
import ContactUsModal from "../../pages/modals/ContactUsModal";
import { AppContext } from "../AppContext";
import NavbarMobileMenu from "./NavbarMobileMenu";
import { Color } from "../../types";
import Image from "../Image";
import Popover, { Placement } from "../Popover";
import NavbarMobileSubmenu from "./NavbarMobileSubmenu";
import "../../styles/Navbar.scss";

const LinkWrapper = styled(Link)`
  display: contents;
`;

const StyledNavbar = styled.nav<{
  $atTop: boolean;
  $mobileSize: boolean;
  $visible: boolean;
}>`
  align-items: center;
  // background-color: ${(props) =>
    props.$atTop ? "transparent" : `rgba(${hexToRgb(Colors.white)}, 0.5)`};
  // background: url(/images/blue-fade.webp) 0% 0% / cover no-repeat;
  // background-color: var(--white);
  // border-bottom: 1px solid var(--primary);
  background-color: #f8f0f2;
  // background: var(--lightPurple);
  background-position: top;
  background-size: calc(var(--min-height) * 2);
  display: flex;
  // justify-content: space-between;
  // padding: 0 7.5vw;
  margin: 0 auto;
  position: fixed;
  // top: ${(props) => (props.$visible ? "0" : "-100")}%;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1060;
  .phoneNumber {
    text-decoration: none;
    color: var(--quinary);
    padding: 0.75rem 1rem;
    border-color: var(--quinary);
    border-radius: 0.25rem;
    // color: var(--white);
    display: block;
    margin: 0 auto;
    font-size: 1rem;
    height: 100px;
    text-align: center;
    @media(max-width: 800px) {
      margin-top: 1rem;
    }
    }
  }
  ${(props) =>
    props.$mobileSize &&
    css`
      max-height: 80px;
      height: 100%;
      padding: 0.5rem 1.5rem;
      a {
        height: 100%;
      }
      svg {
        padding: 0.5rem 0;
      }
    `};
`;

const NavbarList = styled.ul`
  align-items: center;
  // border-right: 1px solid var(--quinary);
  display: flex;
  height: 50px;
  list-style: none;
  padding: 0;
  margin-left: 2rem;
  margin-right: 0.5rem;
  &:last-of-type {
    border-right: none;
  }
  ${StyledButton},
  ${StyledNavbarItem} {
    &:hover {
      background: var(--quinary);
      color: var(--white);
      opacity: 1;
      // border-bottom: 2px solid white;
    }
  }
`;

const LogoImg = styled(Image)`
  width: 90px;
  margin-bottom: -0.5rem;

  @media (max-width: 720px) {
    width: 60px;
    margin-top: 1.5rem;
    margin-bottom: 0;
    width: inherit;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const RightAlignedItems = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 1rem;
  margin-right: 1.5rem;
`;

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();
  const { mobileSize, setOpenLoginModal, user } = useContext(AppContext);
  const scrollTopRef = React.useRef(0);

  const buttonRef = useRef<HTMLButtonElement>();
  const popoverRef = useRef<HTMLDivElement>();
  const [atTop, setAtTop] = useState(true);
  const [visible, setVisible] = useState(true);
  const [isUserOpen, setIsUserOpen] = useState(false);

  const landingInfo = JSON.parse(localStorage.getItem("landingInfo") || "{}");

  const typeformHiddenFields = {
    market_of_interest: "Not Specified",
    source_page: location.pathname,
    utm_source: landingInfo.utm_source || "",
    utm_medium: landingInfo.utm_medium || "",
    anon_user_id: landingInfo.anon_user_id || "",
    user_id: landingInfo.user_id || "",
    utm_campaign: landingInfo.utm_campaign || "",
    utm_term: landingInfo.utm_term || "",
    utm_content: landingInfo.utm_content || "",
    subquery: ""
  };

  const handleMouseOver = (e: any) => {
    // console.log("Button ref", buttonRef);
    e.persist();
    if (e.type === "mouseenter") {
      setIsUserOpen(true);
    } else if (
      e.type === "mouseleave" &&
      !buttonRef.current?.contains(e.relatedTarget) &&
      !popoverRef.current?.contains(e.relatedTarget)
    ) {
      setIsUserOpen(false);
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      // eslint-disable-next-line
      console.log("error signing out: ", error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const st = window.scrollY;
      setVisible(st < scrollTopRef.current || st < 133);
      setAtTop(st < 133);
      scrollTopRef.current = st;
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/login") {
      setOpenLoginModal(true);
    }
    // if (location.pathname === "/contact-us") {
    //   // handleContactUsClick();
    // }
  }, [location]);

  const handleNavigation = (path: string, navSection: string) => {
    // Track the navigation click event
    ahoy.track("navigation_click", {
      section: navSection,
      from: location.pathname,
      to: path,
      timestamp: new Date().toISOString()
    });

    // Navigate to the new path
    history.push(path);
  };

  function handleBlogClick(): void {
    history.push("/blog");
  }

  return (
    <>
      <StyledNavbar
        id="main-navigation"
        $atTop={atTop}
        $visible={visible}
        $mobileSize={mobileSize}
      >
        <Link to="/">
          <LogoImg
            // src={`${process.env.PUBLIC_URL}/logo.webp`}
            loading="eager"
            src={`${process.env.PUBLIC_URL}/images/logo2024.png`}
            alt="Chalet"
            height={mobileSize ? "30px" : "48px"}
            width={mobileSize ? "60px" : "90px"}
          />
        </Link>
        {mobileSize && (
          <NavbarMobileMenu>
            <NavbarMobileSubmenu
              title="Analyze"
              data-id="header_analyze_button"
              style={{
                width: "100%",
                padding: "0.5rem 1rem"
              }}
            >
              {/* <NavbarMobileSubmenu.Button
                style={{
                  width: "100%",
                  padding: "0.5rem 0",
                  alignItems: "center"
                }}
                href="/analyze"
                data-id="navbar_analyze_button"
              >
                Analyze
              </NavbarMobileSubmenu.Button> */}
              <NavbarMobileSubmenu.Button
                href="/analytics/overview"
                data-id="header_analytics_button"
              >
                STR Market Insights
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/analytics/str-calculator"
                data-id="header_airbnb_calculator_button"
              >
                Airbnb Calculator
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/dscr-calculator"
                data-id="header_dscr_calculator_button"
              >
                DSCR Calculator
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/analytics/rental-regulations"
                data-id="header_airbnb_regulation_button"
              >
                Rental Regulation
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/analytics/investor-guides"
                data-id="header_airbnb_investor-guides_button"
              >
                Investor Guides
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/blog"
                data-id="header_blog_button"
              >
                Blog
              </NavbarMobileSubmenu.Button>
            </NavbarMobileSubmenu>{" "}
            <NavbarMobileSubmenu title="Buy" data-id="header_buy_button">
              {/* <NavbarMobileSubmenu.Button
                style={{ textDecoration: "underline", fontWeight: "500" }}
                href="/buy"
                data-id="navbar_buy_button"
              >
                Buy
              </NavbarMobileSubmenu.Button> */}
              <NavbarMobileSubmenu.Button
                href="/real-estate-agents"
                data-id="header_find-real-estate-agents_button"
              >
                Find Airbnb Real Estate Agents
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/str-lenders"
                data-id="header_find-str-lenders_button"
              >
                Find A Short-Term Rental Lender
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/1031-exchange"
                data-id="header_1031-exchange_button"
              >
                1031 Exchange Investment Properties
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/airbnbs-for-sale"
                data-id="header_airbnbs-for-sale_button"
              >
                For Sale Airbnbs
              </NavbarMobileSubmenu.Button>
            </NavbarMobileSubmenu>
            <NavbarMobileSubmenu title="Manage" data-id="header_manage_button">
              <NavbarMobileSubmenu.Button
                href="/cost-segregation"
                data-id="header_cost-seg_button"
              >
                Cost Segregation Specialist
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/property-management"
                data-id="header_find-property-manager_button"
              >
                Find A Property Manager
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/turnover-services"
                data-id="header_turnover-services_button"
              >
                Find A Turnover Service/Airbnb Cleaners
              </NavbarMobileSubmenu.Button>
              {/* <NavbarMobileSubmenu.Button
                href="/remote-manage-your-airbnbs"
                data-id="header_airbnb_consulting_button"
              >
                Chalet Remote Host
              </NavbarMobileSubmenu.Button> */}
            </NavbarMobileSubmenu>
            <Button
              onClick={() => handleNavigation("/sell-your-str", "main_nav")}
              style={{
                width: "100%",
                textAlign: "left",
                padding: "0.5rem 1rem",
                background: "none",
                color: "var(--primary)",
                fontSize: "1.2rem",
                justifyContent: "flex-start",
                fontWeight: 500,
                margin: 0
              }}
            >
              Sell
            </Button>
            {/* <Button
              onClick={() => handleNavigation("/blog", "main_nav")}
              style={{
                width: "100%",
                textAlign: "left",
                padding: "0.5rem 1rem",
                background: "none",
                color: "var(--primary)",
                fontSize: "1.2rem",
                justifyContent: "flex-start",
                fontWeight: 500,
                margin: 0
              }}
            >
              Blog
            </Button> */}
            <PopupButton
              id="DU51pTsh"
              hidden={typeformHiddenFields}
              className="connect-with-realtor_button"
              style={{
                width: "100%",
                textAlign: "left",
                padding: "0.5rem 1rem",
                background: "none",
                color: "var(--quinary)",
                fontSize: "1.2rem",
                border: 0,
                margin: 0,
                boxShadow: "none",
                borderBottom: "1px solid var(--primary)"
              }}
            >
              Find Agents
            </PopupButton>
            {!user && (
              <>
                <Button
                  type={ButtonType.link}
                  style={{
                    // background: "var(--primary)",
                    color: "var(--primary)",
                    fontWeight: "500",
                    fontSize: "1.2rem",
                    // textTransform: "uppercase",
                    justifyContent: "flex-start",
                    // margin: "0 auto",
                    maxWidth: "200px"
                  }}
                  onClick={() => setOpenLoginModal(true)}
                >
                  Login
                </Button>
              </>
            )}
            {user && (
              <>
                <Button
                  type={ButtonType.link}
                  ref={buttonRef}
                  id="user-button"
                  onMouseEnter={handleMouseOver}
                  onMouseLeave={handleMouseOver}
                  style={{
                    fontWeight: "500",
                    // margin: "0 auto",
                    maxWidth: "200px",
                    fontSize: "1.2rem",
                    justifyContent: "flex-start"
                  }}
                >
                  {(user as any)?.attributes ? (
                    <>
                      {(user as any)?.attributes.name?.split(" ")[0]}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="var(--quinary)"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-down"
                      >
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </>
                  ) : (
                    "Login"
                  )}
                </Button>
                <Popover
                  ref={popoverRef}
                  color={Color.quinary}
                  isOpen={isUserOpen}
                  placement={Placement.bottomStart}
                  target="user-button"
                  onMouseLeave={handleMouseOver}
                >
                  <NavbarMenuList>
                    <Button
                      type={ButtonType.link}
                      onClick={signOut}
                      data-id="header_logout_button"
                    >
                      Logout
                    </Button>
                  </NavbarMenuList>
                </Popover>
                {/* {(user as any)?.attributes && (
                  <img
                    src="./images/ProfileIcon.png"
                    alt=""
                    style={{ height: "48px" }}
                  />
                )} */}
              </>
            )}
          </NavbarMobileMenu>
        )}
        {!mobileSize && (
          <NavbarContainer>
            <NavbarList>
              <NavbarMenu
                // onClick={() => handleNavigation("/buy", "main_nav")}
                style={{ fontWeight: "500" }}
                href="/buy"
                title="Buy"
                data-id="header_buy_button"
              >
                <NavbarItem
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default to handle navigation manually
                    handleNavigation("/real-estate-agents", "buy_submenu");
                  }}
                  href="/real-estate-agents"
                  data-id="connect-with-realtor_button"
                >
                  Find An Agent
                </NavbarItem>
                <NavbarItem
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default to handle navigation manually
                    handleNavigation("/str-lenders", "buy_submenu");
                  }}
                  href="/str-lenders"
                  data-id="connect-with-str-lender_button"
                >
                  Find A Short-Term Rental Lender
                </NavbarItem>
                <NavbarItem
                  href="/1031-exchange"
                  data-id="header_1031-exchange_button"
                >
                  1031 Exchange Investment Properties
                </NavbarItem>
                <NavbarItem
                  href="/airbnbs-for-sale"
                  data-id="header_airbnbs-for-sale_button"
                >
                  For Sale Airbnbs
                </NavbarItem>
              </NavbarMenu>
              <Button
                onClick={() => handleNavigation("/sell-your-str", "main_nav")}
                style={{
                  background: "none",
                  color: "var(--primary)",
                  textDecoration: "underline",
                  textTransform: "initial",
                  fontSize: "1.2rem",
                  fontWeight: "400"
                }}
              >
                Sell
              </Button>
              <NavbarMenu
                href="/analyze"
                title="Analyze"
                data-id="header_analyze_button"
              >
                <NavbarItem
                  href="/analytics/overview"
                  data-id="header_best-airbnbs_guides_button"
                >
                  STR Market Insights
                </NavbarItem>
                <NavbarItem
                  href="/analytics/str-calculator"
                  data-id="header_airbnb_calculator_button"
                >
                  Airbnb Calculator
                </NavbarItem>
                <NavbarItem
                  href="/dscr-calculator"
                  data-id="header_dscr_calculator_button"
                >
                  DSCR Calculator
                </NavbarItem>
                <NavbarItem
                  href="/analytics/rental-regulations"
                  data-id="header_airbnb_regulation_button"
                >
                  Rental Regulation
                </NavbarItem>
                <NavbarItem
                  href="/analytics/investor-guides"
                  data-id="header_airbnb_investor-guides_button"
                >
                  Investor Guides
                </NavbarItem>
                <NavbarItem href="/blog" data-id="header_blog_button">
                  Blog
                </NavbarItem>
              </NavbarMenu>
              <NavbarMenu
                href="/property-management"
                title="Manage"
                data-id="header_manage_button"
                style={{ fontWeight: "500" }}
              >
                <NavbarItem
                  href="/cost-segregation"
                  data-id="header_cost-seg_button"
                >
                  Cost Segregation Specialists
                </NavbarItem>
                <NavbarItem
                  href="/property-management"
                  data-id="header_find-property-manager_button"
                >
                  Find A Property Manager
                </NavbarItem>
                <NavbarItem
                  href="/turnover-services"
                  data-id="header_turnover-services_button"
                >
                  Find A Turnover Service/Airbnb Cleaners
                </NavbarItem>
                {/* <NavbarItem
                  href="/remote-manage-your-airbnbs"
                  data-id="header_airbnb_consulting_button"
                >
                  Chalet Remote Host
                </NavbarItem> */}
              </NavbarMenu>
            </NavbarList>
            <RightAlignedItems>
              <Button
                type={ButtonType.link}
                style={{
                  background: "none",
                  color: "var(--primary)",
                  textDecoration: "underline",
                  textTransform: "initial",
                  fontSize: "1.2rem",
                  fontWeight: "400"
                }}
                onClick={() => handleBlogClick()}
              >
                Blog
              </Button>
              <Button
                onClick={() =>
                  handleNavigation("/cost-segregation", "main_nav")
                }
                style={{
                  background: "none",
                  color: "var(--primary)",
                  textDecoration: "underline",
                  textTransform: "initial",
                  fontSize: "1.2rem",
                  fontWeight: "400"
                }}
              >
                Cost Segregation
              </Button>
              <PopupButton
                id="M700RBmq"
                data-id="connect-with-str-lender_button"
                className="connect-with-str-lender_button"
                hidden={typeformHiddenFields}
                style={{
                  background: "var(--sixth)",
                  color: "var(--white)",
                  // textTransform: "uppercase",
                  display: "block",
                  fontSize: "1rem",
                  fontWeight: "500",
                  padding: "0.5rem 1rem",
                  border: 0,
                  borderRadius: "0.25rem",
                  cursor: "pointer"
                }}
              >
                Find STR Lenders
              </PopupButton>
              <PopupButton
                id="DU51pTsh"
                data-id="connect-with-realtor_button"
                className="connect-with-realtor_button"
                hidden={typeformHiddenFields}
                style={{
                  background: "var(--linearGradient)",
                  color: "var(--white)",
                  // textTransform: "uppercase",
                  display: "block",
                  fontSize: "1rem",
                  fontWeight: "500",
                  padding: "0.5rem 1rem",
                  border: 0,
                  borderRadius: "0.25rem",
                  cursor: "pointer"
                }}
              >
                Find Agents
              </PopupButton>
              {!user && (
                <Button
                  type={ButtonType.link}
                  style={{
                    background: "none",
                    color: "var(--primary)",
                    textTransform: "uppercase",
                    textDecoration: "underline"
                  }}
                  onClick={() => setOpenLoginModal(true)}
                >
                  Login
                </Button>
              )}
              {user && (
                <>
                  <Button
                    type={ButtonType.link}
                    ref={buttonRef}
                    id="user-button"
                    onMouseEnter={handleMouseOver}
                    onMouseLeave={handleMouseOver}
                    style={{ textTransform: "uppercase" }}
                  >
                    {(user as any)?.attributes ? (
                      <>
                        {(user as any)?.attributes.name?.split(" ")[0]}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="var(--quinary)"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-arrow-down"
                        >
                          <polyline points="6 9 12 15 18 9" />
                        </svg>
                      </>
                    ) : (
                      "Login"
                    )}
                  </Button>
                  <Popover
                    ref={popoverRef}
                    color={Color.quinary}
                    isOpen={isUserOpen}
                    placement={Placement.bottomStart}
                    target="user-button"
                    onMouseLeave={handleMouseOver}
                  >
                    <NavbarMenuList>
                      <Button
                        type={ButtonType.link}
                        onClick={signOut}
                        data-id="header_logout_button"
                      >
                        Logout
                      </Button>
                    </NavbarMenuList>
                  </Popover>
                  {/* {(user as any)?.attributes && (
                    <img
                      src="./images/ProfileIcon.png"
                      alt=""
                      style={{ height: "48px" }}
                    />
                  )} */}
                </>
              )}
            </RightAlignedItems>
          </NavbarContainer>
        )}
      </StyledNavbar>
      <ContactUsModal />
    </>
  );
};

export default Navbar;
