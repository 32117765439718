import React, { useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link, useLocation, useParams } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import Slider from "react-slick";
import { AppContext } from "../../components/AppContext";
import Text from "../../components/Text";
import Header from "../../components/Header";
import useCookies from "../../hooks/useCookies";
import Flex from "../../components/Flex";
import List from "../../components/List";
import SectionFooter from "../../components/SectionFooter";
import SplitView from "../../components/SplitView";
import Group from "../../components/Group";
import Section from "../../components/Section";
import { Color } from "../../types";
import "../../styles/STRLenders.scss";
import LiveMarkets from "../../liveMarkets";
import Image from "../../components/Image";
import { VerticalDivider, HorizontalDivider } from "../../components/Divider";

const Container = styled(Flex)`
  // flex-direction: column;
  font-size: 2.5rem;
  height: 100vh;
  width: 80vw;
  margin: 0 auto;
  justify-content: center;
  line-height: 1;
  h1 {
    font-weight: 300;
    font-size: 3.5rem;
    // text-align: center;
    margin-bottom: 0;
    text-align: left;
    // padding-bottom: 1rem;
  }
  h3 {
    font-weight: 300;
    font-size: 1.6rem;
    // text-align: center;
    margin: 0;
  }
  video {
    margin: 2rem;
    max-width: 450px;
    border-radius: 0.5rem;
  }
  padding: 10rem 0;
  .first ${Text} {
    white-space: nowrap;
    font-size: 3.5rem;
  }
  .second h1 {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
    color: var(--primary);
  }
  .third ${Text} {
    font-size: 1.5rem;
  }
  .first {
    padding: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
    // width: 50%;
  }
  .second,
  .third {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-top: 0.75rem;
    padding: 1rem;
    padding-left: 1rem;
    // width: 50%;
  }
  .second {
    padding-top: 0;
  }
  .third {
    // display: block;
    // text-align: left;
    padding: 0rem;
  }
  ${Group} {
    :first-of-type {
      margin-top: 6rem;
    }
  }

  @media (max-width: 810px) {
    // justify-content: start;
    align-items: flex-start;
    margin-top: 4rem;
    padding-left: 1rem;
    width: 100vw;
    padding-right: 1rem;
    padding-bottom: 0rem;
    h3 {
      font-size: 2rem;
    }
    .first {
      text-align: center;
      h1 {
        font-size: 2.2rem;
        text-align: left;
        ${Text} {
          font-size: 2.2rem;
          text-align: left;
          line-height: 2.2rem;
        }
      }
    }
    .second {
      // display: none;
      h3 {
        font-size: 1.2rem;
        text-align: left;
        ${Text} {
          font-size: 1.2rem !important;
        }
      }
    }
    video {
      display: none;
    }
    .second {
      padding-bottom: 1rem;
    }
    .third ${Text} {
      padding-bottom: 1rem;
    }
    .second,
    .third {
      text-align: center;
    }
    .third {
      flex-direction: column;
    }
  }
`;

const FAQSection = styled(Section)`
  background: var(--white);
  //   padding: 3rem 2rem;
  //   max-width: 95vw;
  box-shadow: none;
  margin: 0 auto;

  h2 {
    color: var(--primary);
    text-align: center;
    margin-bottom: 2rem;
  }

  .faq-item {
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    padding: 1.5rem;
    background-color: var(--lightPurple);
    border: 1px solid var(--primary);

    h3 {
      color: var(--primary);
      margin-bottom: 1rem;
    }

    p {
      color: var(--secondary);
      line-height: 1.6;
    }

    ol {
      padding-left: 1.5rem;
      margin-top: 0.5rem;
    }

    li {
      margin-bottom: 0.5rem;
      font-weight: 300;
    }
  }
`;

const CompTable = ({
  className
}: // featureProp,
{
  className?: string;
}) => {
  const classes = className;

  // const features = featureProp;

  return (
    <div className={classes}>
      {/* <h2>{title}</h2> */}
      <div>
        <table>
          <thead>
            <tr>
              <th className="keyfeatures">
                <div>
                  <Text style={{ fontWeight: 700, fontSize: "1.5rem" }}>
                    Key Features
                  </Text>
                </div>
              </th>
              <th className="dscr">
                <div>
                  <Text style={{ fontWeight: 700, fontSize: "1.5rem" }}>
                    DSCR Loans
                  </Text>
                </div>
              </th>
              <th className="conventional">
                <div>
                  <Text style={{ fontWeight: 700, fontSize: "1.5rem" }}>
                    Conventional Loans
                  </Text>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="feature">Approval Basis</td>
              <td>Based on property&apos;s income potential</td>
              <td>
                Based on borrower&apos;s personal income, credit, and
                debt-to-income (DTI) ratio
              </td>
            </tr>
            <tr>
              <td className="feature">Typical Down Payment</td>
              <td>20-25%</td>
              <td>20-25%</td>
            </tr>
            <tr>
              <td className="feature">Income Verification</td>
              <td>No personal income verification required</td>
              <td>
                Requires documentation of personal income (W-2s, tax returns,
                etc.)
              </td>
            </tr>
            <tr>
              <td className="feature">Closing Speed</td>
              <td>Faster due to streamlined underwriting</td>
              <td>Longer due to detailed borrower documentation</td>
            </tr>
            <tr>
              <td className="feature">Use Case</td>
              <td>
                Scaling STR portfolios or acquiring income-focused properties
              </td>
              <td>
                Acquiring long-term rental properties with predictable tenant
                income
              </td>
            </tr>
            <tr>
              <td className="feature">Repeatability</td>
              <td>Can be used repeatedly to grow portfolios</td>
              <td>
                May face limits based on personal income or number of financed
                properties
              </td>
            </tr>
            <tr>
              <td className="feature">Flexibility</td>
              <td>Designed for STRs with variable income</td>
              <td>More rigid, suited for stable, long-term rental income</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  // centerMode: true,
  // centerPadding: "60px",
  rows: 1,
  // variableWidth: true,

  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
        // centerMode: true,
        // centerPadding: "60px"
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false
        // centerPadding: "60px"
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
        centerPadding: "60px"
      }
    }
  ]
};

const Stars = () => <div className="stars">★★★★★</div>;

const DisclaimerSection = styled.div`
  background: var(--lightPurple);
  padding: 3rem 2rem;
  margin-top: 2rem;
  border-top: 1px solid var(--sixth);

  .disclaimer-container {
    max-width: 1200px;
    margin: 0 auto;

    h3 {
      color: var(--primary);
      font-size: 1.3rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .disclaimer-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }

    .disclaimer-item {
      background: var(--white);
      padding: 1.5rem;
      border-radius: 0.5rem;
      border: 1px solid var(--sixth);

      h4 {
        color: var(--sixth);
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
        font-weight: 600;
      }

      p {
        color: var(--primary);
        font-size: 0.9rem;
        line-height: 1.5;
      }
    }
  }
`;

const STRLenders = () => {
  const location = useLocation();
  const { getAllCookies } = useCookies();
  const { marketPathParam } = useParams<any>();
  const searchParams = new URLSearchParams(location.search);

  const { mobileSize } = useContext(AppContext);

  const bodyRef = useRef<HTMLDivElement>();

  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });

  const titleString = `Short-Term Rental Financing & DSCR Loans ${
    LiveMarkets[marketPathParam] ? ` | ${LiveMarkets[marketPathParam]}` : ""
  }`;
  const descriptionString = `Connect with specialized STR lenders offering DSCR and conventional loans for Airbnb properties${
    LiveMarkets[marketPathParam] ? ` in ${LiveMarkets[marketPathParam]}` : ""
  }. Get flexible financing options designed for short-term rental investors with competitive rates and streamlined approvals.`;

  const urlSlug = marketPathParam
    ? `https://www.getchalet.com/str-lenders/${marketPathParam}`
    : "https://www.getchalet.com/str-lenders";

  // Get landing info from localStorage including original UTM params
  const landingInfo = JSON.parse(localStorage.getItem("landingInfo") || "{}");

  const typeformHiddenFields = {
    market_of_interest: LiveMarkets[marketPathParam] || "Not Specified",
    source_page: urlSlug,
    utm_source: landingInfo.utm_source || "",
    utm_medium: landingInfo.utm_medium || "",
    anon_user_id: landingInfo.anon_user_id || "",
    user_id: landingInfo.user_id || "",
    utm_campaign: landingInfo.utm_campaign || "",
    utm_term: landingInfo.utm_term || "",
    utm_content: landingInfo.utm_content || "",
    subquery: marketPathParam || ""
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={urlSlug} />
        <title>{titleString}</title>
        <meta property="og:type" content="website" />
        <meta name="description" content={descriptionString} />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={descriptionString} />
        <meta property="og:image" content="" />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Container ref={bodyRef as any}>
        <div
          style={{
            maxWidth: mobileSize ? "100%" : "80vw",
            paddingRight: mobileSize ? "0" : "0rem"
          }}
        >
          <div id="left-content">
            <h1>
              Personalized Financing Solutions for Short-Term Rental Investors{" "}
            </h1>
            <h2>
              Loan Options Designed for Your Airbnb Investment Goals
              {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}
            </h2>
            <PopupButton
              id="M700RBmq"
              hidden={typeformHiddenFields}
              shareGaInstance="true"
              data-id="connect-with-str-lender_button"
              className="connect-with-str-lender_button"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--quinary)"
                  : "var(--quinary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem"
              }}
            >
              Connect With STR Lender
            </PopupButton>
          </div>
        </div>
        <div style={{ display: "block", maxWidth: "50%" }}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/str-lenders.png`}
            alt="Chalet Partner Real Estate Agents With STR Investing Expertise"
            $mobileSize={mobileSize}
            style={{
              gridRowStart: mobileSize ? "1" : "inherit",
              maxWidth: mobileSize ? "100%" : "400px",
              display: mobileSize ? "none" : "block",
              borderRadius: "0.25rem"
            }}
          />
        </div>
      </Container>
      <Section
        style={{
          boxShadow: "none",
          maxWidth: "100vw",
          background: "var(--white)"
        }}
      >
        <Header
          size={2}
          style={{
            textAlign: "center",
            marginBottom: "0rem",
            paddingBottom: "0rem",
            fontSize: mobileSize ? "1.7rem" : "2.2rem"
          }}
        >
          Why Work With a Chalet Lending Partner
        </Header>
        <Header
          size={4}
          style={{
            color: "var(--sixth)",
            fontWeight: 400,
            // marginBottom: "3rem",
            maxWidth: mobileSize ? "100%" : "85vw",
            margin: mobileSize ? "0 auto" : "0rem auto 3rem"
            // paddingTop: "0rem",
            // marginTop: "0rem"
          }}
        >
          Our lender partners{" "}
          {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`} offer
          unparalleled flexibility, specializing in both Debt Service Coverage
          Ratio (DSCR) loans and conventional loans to meet the unique needs of
          short-term rental investors.
        </Header>
        <div className="tools-grid">
          <div className="tool-card">
            <Image
              src={`${process.env.PUBLIC_URL}/images/analyze.png`}
              alt="Analyze STR Markets"
            />
            <div className="tool-content">
              <Header
                style={{
                  textAlign: "left",
                  paddingLeft: 0,
                  fontSize: "1.7rem",
                  fontWeight: 600
                }}
                size={3}
              >
                Investor-Focused Lending
              </Header>
              <Text
                style={{
                  fontSize: "1.5rem"
                }}
              >
                Our lending partners specialize in strategies designed for
                short-term rental investors. With a deep understanding of the
                unique challenges and opportunities in the STR market, they
                provide tailored loan options to help you meet your investment
                goals.
              </Text>
            </div>
          </div>
          <div className="tool-card">
            <Image
              src={`${process.env.PUBLIC_URL}/images/buy.png`}
              alt="Browse For-Sale Airbnb Listings"
            />
            <div className="tool-content">
              <Header
                style={{
                  textAlign: "left",
                  paddingLeft: 0,
                  fontSize: "1.7rem",
                  fontWeight: 600
                }}
                size={3}
              >
                DSCR Options
              </Header>
              <Text style={{ fontSize: "1.5rem" }}>
                Debt Service Coverage Ratio (DSCR) loans are ideal for scaling
                your short-term rental portfolio. These loans prioritize the
                property’s income potential, offering faster approvals, flexible
                terms, and repeatable financing to fuel your growth.
              </Text>
            </div>
          </div>
          <div className="tool-card">
            <Image
              src={`${process.env.PUBLIC_URL}/images/manage.png`}
              alt="Estimate STR Revenue"
            />
            <div className="tool-content">
              <Header
                style={{
                  textAlign: "left",
                  paddingLeft: 0,
                  fontSize: "1.7rem",
                  fontWeight: 600
                }}
                size={3}
              >
                Conventional Loans
              </Header>
              <Text style={{ fontSize: "1.5rem" }}>
                For those pursuing traditional investment properties, our
                partners offer conventional loan options. These loans provide
                competitive rates and predictable terms, making them a
                dependable choice for stable, long-term rental income
                strategies.
              </Text>
            </div>
          </div>
        </div>
      </Section>
      <Section
        id="home-statistics"
        style={{
          // backgroundImage: `url(&apos;${process.env.PUBLIC_URL}/images/radial-gradient.webp&apos;)`,
          background: "var(--sixth)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <div className="stats">
          <span id="alt-red">Connected Investors With</span>
          <span id="highlight">100M+</span>
          <span id="alt-black">in Short-Term Rental Assets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-black">Short-Term Rental Partners</span>
          <span id="highlight">All 50 states</span>
          <span id="alt-black">200+ Markets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-red">Analyzing</span>
          <span id="highlight">1M+</span>
          <span id="alt-red">Short-Term Rentals</span>
        </div>
      </Section>
      <Group id="section1">
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit"
            // maxWidth: "%"
          }}
        >
          <Header size={2} style={{ marginBottom: "2rem" }}>
            Key Differences Between DSCR and Conventional Loans for STR
            Investors
          </Header>
          <div className="dscr-conventional-tables">
            <CompTable
              className="conventional-dscr-table"
              // featureProp={compareFeatures}
              // title="Short-Term Rental Tools and Resources"
            />
            <Text style={{ marginTop: "1rem", textAlign: "center" }}>
              Want to evaluate your property&apos;s DSCR? Try our{" "}
              <a
                href="/dscr-calculator"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "var(--fourth)", textDecoration: "underline" }}
              >
                DSCR Calculator
              </a>
              .
            </Text>
          </div>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Section
        style={{
          boxShadow: "none",
          background: "var(--sixth)",
          maxWidth: "100%",
          padding: "4rem 0"
        }}
      >
        <div className="get-started-container">
          <div className="get-started-content">
            <h2>Ready to Secure Financing for Your STR Investment?</h2>
            <p>
              Connecting with our DSCR and conventional lending partners{" "}
              {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`} is
              completely free and comes with no obligation.
            </p>
            <div className="get-started-buttons">
              {/* <Button onClick={() => history.push("/buying-an-airbnb")}>
                I am buying an Airbnb
              </Button> */}
              <PopupButton
                id="M700RBmq"
                shareGaInstance="true"
                hidden={{
                  ...typeformHiddenFields,
                  source_page: "/(cta-bottom-homepage)",
                  start_point: "selling"
                }}
                data-id="connect-with-str-lender_button"
                className="connect-with-str-lender_button_top btn-consult"
                style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
              >
                Get Started
              </PopupButton>
              {/* <PopupButton
                id="M700RBmq"
                shareGaInstance="true"
                hidden={{
                  source_page: "/(cta-bottom-homepage)",
                  start_point: "ancillary"
                }}
                data-id="talk-to-str-expert"
                className="talk-to-str-expert_button_top btn-consult"
                style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
              >
                I am looking for financing
              </PopupButton> */}
            </div>
          </div>
          <div className="get-started-image">
            <Image
              src={`${process.env.PUBLIC_URL}/images/house-with-palm-trees.svg`}
              alt="House with palm trees illustration"
            />
          </div>
        </div>
      </Section>
      <Section
        style={{
          boxShadow: "none",
          background: "var(--white)",
          maxWidth: "100%"
        }}
      >
        <div className="why-choose-dscr">
          <h2>Why Choose DSCR Loans for STR Investors?</h2>
          <div className="benefits-list">
            <div className="benefit-item">
              • Easier Qualification: Approvals focus on the property&apos;s
              income potential, not your personal financials. Use our{" "}
              <a
                href="/dscr-calculator"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "var(--fourth)", textDecoration: "underline" }}
              >
                DSCR Calculator
              </a>{" "}
              to evaluate your property&apos;s potential qualification.
            </div>
            <div className="benefit-item">
              • Quick Closings: Streamlined underwriting accelerates approvals,
              helping you secure properties faster.
            </div>
            <div className="benefit-item">
              • Flexible Terms: Competitive rates, low down payments, and
              adjustable terms cater to STR-specific needs.
            </div>
            <div className="benefit-item">
              • Repeatable for Growth: DSCR loans can be used repeatedly,
              enabling you to scale your STR portfolio efficiently.
            </div>
          </div>
          <PopupButton
            id="M700RBmq"
            hidden={typeformHiddenFields}
            shareGaInstance="true"
            data-id="connect-with-str-lender_button"
            className="connect-with-str-lender_button"
          >
            Connect with a DSCR Lender{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}
          </PopupButton>
        </div>
      </Section>
      <Section
        style={{
          boxShadow: "none",
          background: "var(--lightPink)",
          maxWidth: "100%"
        }}
      >
        <div className="when-conventional">
          <h2>When Conventional Financing Makes Sense</h2>
          <div className="loan-types">
            <div className="loan-type">
              • Primary Residence Loans (House-Hack): Perfect for investors
              planning to live in one unit of a property while renting out
              others to generate income.
            </div>
            <div className="loan-type">
              • Second Home Loans: A great option for those seeking to purchase
              a vacation property that combines personal use with short-term
              rental income.
            </div>
            <div className="loan-type">
              • Investor Loans: Designed for traditional income properties,
              these loans provide predictable terms for long-term rental
              investments. For investors choosing the conventional path, most
              will rely on an investor loan to build their portfolios,
              leveraging personal income and credit to finance rental
              properties.
            </div>
          </div>
          <PopupButton
            id="M700RBmq"
            hidden={typeformHiddenFields}
            shareGaInstance="true"
            data-id="connect-with-str-lender_button"
            className="connect-with-str-lender_button"
          >
            Connect with a Conventional Lender{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}
          </PopupButton>
        </div>
      </Section>
      <Section
        style={{
          boxShadow: "none",
          // background: `url("${process.env.PUBLIC_URL}/images/blue-fade.webp")`,
          // background: `url("${process.env.PUBLIC_URL}/images/about2.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          background: "var(--lightPurple)",
          maxWidth: "100%",
          // padding: "2rem 3rem",
          position: "relative"
        }}
      >
        <Header size={2} style={{ margin: "2rem auto 0" }}>
          Testimonials
        </Header>
        <div
          style={{
            width: "100%",
            margin: "0 auto",
            display: "block"
          }}
        >
          {/* <Slider {...sliderSettings}>
          i
          </Slider> */}
          <div className="testimonials-container">
            <div className="testimonial">
              <Header
                size={3}
                style={{ paddingBottom: "0.5rem", textAlign: "center" }}
              >
                Jessica M., Asheville, NC
              </Header>
              <Text>
                The flexibility of their lenders was unmatched. I was able to
                choose between a DSCR loan and a conventional loan, making it so
                much easier to finance my Airbnb investment.
              </Text>
              <Stars />
            </div>
            <div className="testimonial">
              <Header
                size={3}
                style={{ paddingBottom: "0.5rem", textAlign: "center" }}
              >
                Mike R., Sedona, AZ
              </Header>
              <Text>
                Their lender partners truly understood the unique needs of
                short-term rental investors. I got a competitive loan that fit
                my budget and timeline perfectly.
              </Text>
              <Stars />
            </div>
            <div className="testimonial">
              <Header
                size={3}
                style={{ paddingBottom: "0.5rem", textAlign: "center" }}
              >
                Sarah T., Myrtle Beach, SC
              </Header>
              <Text>
                I’ve worked with other lenders before, but none offered the
                expertise in both DSCR and conventional loans like they did. The
                process was smooth and stress-free!
              </Text>
              <Stars />
            </div>
          </div>
        </div>
      </Section>
      <FAQSection>
        <Header size={2}>Frequently Asked Questions</Header>
        <div className="faq-item">
          <Header size={2}>
            🤝 Will I receive unwanted calls from multiple lenders after using
            Chalet to connect with financing options{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}?
          </Header>
          <Text>
            No, Chalet ensures your contact information is only shared with the
            specific lenders you choose to connect with{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}. You
            remain in control, with the option to reach out to additional
            lenders if desired. Chalet and its lending partners also adhere to
            strict privacy policies to protect your information.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={2}>
            💰 Why should I use Chalet to find a lender for my short-term rental
            investment{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}?
          </Header>
          <Text>
            🔄 Chalet connects you with lenders specializing in short-term
            rental (STR) financing. Unlike generic recommendations, our vetted
            partners offer loan options tailored to your unique investment
            strategy. Chalet itself does not provide loans or make credit
            decisions; all financing solutions are offered by licensed,
            independent lenders{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={2}>
            🏠 Are these loan options available for refinancing an existing
            Airbnb or short-term rental property{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}?
          </Header>
          <Text>
            Yes, our lending partners provide refinancing options for Airbnb and
            STR properties. Whether you’re looking to improve loan terms or
            access cash for new investments, DSCR loans and other options are
            available. Specific terms and availability depend on the lender and
            your qualifications.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={2}>
            📣 Can first-time investors qualify for loans on short-term rental
            properties?
          </Header>
          <Text>
            Absolutely! Many of our lending partners offer programs designed for
            both first-time and experienced investors. DSCR loans, in
            particular, focus on the property’s income potential, making them
            accessible even for new investors. Use our{" "}
            <a
              href="/dscr-calculator"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "var(--fourth)", textDecoration: "underline" }}
            >
              DSCR Calculator
            </a>{" "}
            to see if your property might qualify.
          </Text>
          {/* <List
            items={[
              <Text>
                We share your listing with our email subscribers, one of the
                largest proprietary lists of Airbnb rental investors in the
                country.
              </Text>,
              <Text>
                Your property will be featured on our dedicated Airbnb
                properties for sale section on our website.
              </Text>,
              <Text>
                We promote featured listings through targeted social media
                campaigns and collaborate with our network of experienced real
                estate agents and their partners to find interested buyers.
              </Text>
            ]} */}
          {/* /> */}
        </div>
        <div className="faq-item">
          <Header size={2}>
            📅 How do DSCR loans differ from conventional investor loans?
          </Header>
          <Text>
            DSCR loans base approval on the property&apos;s income potential,
            making them ideal for scaling short-term rental portfolios.
            Conventional loans rely on personal income, credit, and
            debt-to-income (DTI) ratios, often making them a better fit for
            traditional rental properties. To better understand if a DSCR loan
            is right for you, try our{" "}
            <a
              href="/dscr-calculator"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "var(--fourth)", textDecoration: "underline" }}
            >
              free DSCR Calculator
            </a>{" "}
            to evaluate your property&apos;s debt service coverage ratio.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            📣 Are there any limits on the number of properties I can finance
            with a DSCR loan{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}?
          </Header>
          <Text block>
            DSCR loans are designed for scalability, so there’s no fixed limit
            on the number of properties you can finance. As long as the property
            meets the lender’s income requirements (which you can check using
            our{" "}
            <a
              href="/dscr-calculator"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "var(--fourth)", textDecoration: "underline" }}
            >
              DSCR Calculator
            </a>
            ), you can continue to grow your portfolio. Conventional loans may
            impose limits based on personal income or the number of mortgages
            you hold.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            📣 Does Chalet operate as a lender{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}?
          </Header>
          <Text block>
            No, Chalet Realty is a licensed real estate brokerage, not a lender.
            We connect clients with independently licensed lending partners who
            provide financing solutions. Chalet does not provide loans, make
            credit decisions, or guarantee loan approval{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            📣 How does Chalet ensure compliance with lending regulations?{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}?
          </Header>
          <Text block>
            Chalet only partners with vetted lenders who are licensed at the
            state and federal levels. We comply with all Fair Lending and Equal
            Housing Opportunity regulations to ensure equitable access to
            financing.
          </Text>
        </div>
      </FAQSection>
      <DisclaimerSection>
        <div className="disclaimer-container">
          <h3>Important Disclosures</h3>
          <div className="disclaimer-grid">
            <div className="disclaimer-item">
              <h4>Equal Housing Statement</h4>
              <p>
                Chalet and its lending partners comply with all Fair Lending and
                Equal Housing Opportunity laws. We are committed to providing
                equal access to financing for all individuals, regardless of
                race, color, religion, national origin, sex, familial status, or
                disability.
              </p>
            </div>
            <div className="disclaimer-item">
              <h4>Privacy Statement</h4>
              <p>
                Your privacy is important to us. Chalet ensures that your
                information is only shared with the lenders you choose to
                connect with and is never sold or shared without consent.
              </p>
            </div>
            <div className="disclaimer-item">
              <h4>Licensing Disclosure</h4>
              <p>
                Chalet is a licensed real estate brokerage, not a lender. All
                financing solutions are offered by independently operated,
                licensed lending partners. Chalet does not make loans or credit
                decisions and cannot guarantee loan approval.
              </p>
            </div>
            <div className="disclaimer-item">
              <h4>General Disclaimer</h4>
              <p>
                Loan terms, rates, and qualifications vary by lender. All loan
                approvals are subject to lender underwriting and borrower
                qualifications.
              </p>
            </div>
          </div>
        </div>
      </DisclaimerSection>
    </>
  );
};

export default STRLenders;
