/* eslint-disable camelcase */
import React, { useCallback, useMemo, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import useSWR from "swr";
import styled, { css } from "styled-components";
import Markdown from "markdown-to-jsx";
import { PopupButton } from "@typeform/embed-react";
import {
  GoogleMap,
  useLoadScript,
  Polygon,
  OverlayView
} from "@react-google-maps/api";
import { AppContext } from "../../components/AppContext";
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import AnalyticsHeader from "./components/AnalyticsHeader";
import Flex from "../../components/Flex";
import GaugeChart from "./components/GaugeChart";
import PieChart from "./components/PieChart";
import BarChart from "./components/BarChart";
import ZipcodeModal from "./components/ZipcodeModal";
import {
  compactCurrencyNotation,
  currencyNotation,
  numberNotation,
  percentNotation
} from "../../utils/numberFormatters";
import GrossYield from "./components/GrossYield";
import { Colors } from "../../components/GlobalStyle";
import Star from "../../icons/Star";
import Header from "../../components/Header";
import fetcher from "../../utils/fetch";
import AnalyticsModal from "./components/AnalyticsModal";
import LiveMarkets from "../../liveMarkets";
import SkeletonLoader from "../../components/SkeletonLoader";
import {
  AnalyticsBox,
  AnalyticsBoxTitle,
  ChartWrapper
} from "./components/AnalyticsBoxes";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell
} from "../../components/Table";
import AreaChart from "../../components/AreaChart"; // Add this import
import Tooltip from "../../components/Tooltip"; // Add this import

export interface Amenities {
  "Home Theater": string;
  "Free Parking": string;
  Sauna: string;
  Pool: string;
  Gym: string;
  "Hot Tub": string;
  "Pet Friendly": string;
  Balcony: string;
}

// Update the RateData interface to include all bedroom types
export interface RateData {
  market: number;
  Studio: number;
  "1 Bedroom": number;
  "2 Bedroom": number;
  "3 Bedroom": number;
  "4 Bedroom": number;
  "5 Bedroom": number;
  [key: string]: number; // Add this index signature
}

export interface Hosts {
  host_name: string;
  total_listings: string;
  avg_rating: number;
  city: string;
  data_of_analysis: string;
}
export interface MarketData {
  map_center: google.maps.LatLng | google.maps.LatLngLiteral | undefined;
  zipcode_data: any;
  market: string;
  updated: string;
  annual_revenue: number;
  annual_revenue_max: number;
  supply: RateData;
  average_daily_rate: RateData;
  property_tax: number;
  "airbnb-regulation": string;
  occupancy_rate: RateData;
  gross_yield: string;
  us_gross_yield_ranking: string;
  unemployment_rate: Record<string, string>;
  zillow_home_value_data: Record<string, number>;
  host_info: Hosts[];
  most_impactful_amenities: Amenities;
  demand_source: Record<string, string>;
  row_one_text: Record<string, string>;
  row_two_text: Record<string, string>;
  row_three_text: Record<string, string>;
  zipcode_polygons: PolygonData[]; // Add this line
}

const FAQSection = styled.section`
  background: var(--white);
  padding: 4rem 2rem;
  max-width: 95vw;
  margin: 0 auto;
  @media (max-width: 800px) {
    padding: 1rem 1rem;
    box-shadow: none;
  }
  h2 {
    color: var(--primary);
    text-align: center;
    margin-bottom: 2rem;
  }

  .faq-item {
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h3 {
      color: var(--primary);
      margin-bottom: 1rem;
    }

    p {
      color: var(--secondary);
      line-height: 1.6;
    }
  }
`;

const AnalyticRow = styled(Flex)<{ $reverse?: boolean; $isFirstRow?: boolean }>`
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 0.5rem;
  margin: 0rem 0rem;
  // margin-top: 1rem;
  padding: 1rem;

  ${(props) =>
    props.$reverse &&
    css`
      flex-direction: row-reverse;
    `};

  ${(props) =>
    props.$isFirstRow &&
    css`
      @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 40% 60%;
        grid-template-areas:
          "text charts"
          "text charts";
      }
    `}

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--primary);
  // }

  @media (max-width: 1023px) {
    flex-direction: column;
    margin: 0;
  }
`;

const AnalyticsTitle = styled(Flex)`
  // border-bottom: 1px solid var(--primary);
  justify-content: flex-end;
  flex: 1 1 100%;
  gap: 1rem;
  margin: 0 1rem;
  padding: 1rem;
  padding-bottom: 0;

  h1 {
    font-size: 1.3rem;
    font-weight: inherit;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .analytics-title-updated,
  .analytics-title-market,
  .analytics-title-description,
  .analytics-beta {
    font-weight: 400;
  }

  .analytics-title-updated,
  .analytics-title-market {
    color: var(--quinary);
  }
  .analytics-beta {
    color: var(--white);
    background: var(--quinary);
    padding: 0 0.25rem;
    border-radius: 0.25rem;
    margin-left: -0.75rem;
  }

  .analytics-title-description {
    color: var(--primary);
    position: relative;

    &:after {
      background-color: var(--primary);
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      width: 2px;
    }

    &:before {
      left: -0.5rem;
    }

    &:after {
      right: -0.5rem;
    }
  }

  @media (max-width: 720px) {
    justify-content: center;
    margin: 0;
  }
`;

const AnalyticSection = styled(Flex)<{
  $column?: boolean;
  $halfColumn?: boolean;
  $quarterColumn?: boolean;
  $third?: boolean;
  $twoFifth?: boolean;
  $verticalSplit?: boolean;
  $isFirstRowText?: boolean;
}>`
  align-items: flex-start;
  flex: 1 1 100%;
  text-align: left;

  ${(props) =>
    props.$isFirstRowText &&
    css`
      @media (min-width: 1024px) {
        grid-column: 1;
      }
    `}

  ${(props) =>
    props.$third &&
    css`
      flex: 1 1 calc(33.333% - 0.5rem);
      min-width: 200px;
    `};

  ${(props) =>
    props.$quarterColumn &&
    css`
      flex: 1 1 25%;
    `};

  ${(props) =>
    props.$twoFifth &&
    css`
      flex: 1 1 150%;
    `};

  ${(props) =>
    props.$halfColumn &&
    css`
      flex: 1 1 50%;
    `};

  ${(props) =>
    props.$column &&
    css`
      flex-direction: column;
    `};

  ${(props) =>
    props.$verticalSplit &&
    css`
      align-self: stretch;
      gap: 0.5rem;
    `};

  p {
    margin: 0 0 1rem;
  }

  @media (max-width: 815px) {
    flex: 1 1 calc(50% - 2rem);
  }

  @media (max-width: 410px) {
    flex: 1 1 100%;
    width: 100%;
  }
`;

const AnalyticsSplitWrapper = styled(Flex)`
  align-items: stretch;
  gap: 0.5rem;
  width: 100%;
  flex-wrap: wrap;

  // Add margin bottom when followed by another chart
  & + & {
    margin-top: 0.5rem;
  }
`;

const AnalyticsTextWrapper = styled(Flex)`
  align-items: flex-start;
  padding: 0 0.5rem;
  flex-direction: column;
  flex: 1 1 100%;
  font-weight: 300;
  p {
    font-size: 1.2rem;
    font-weight: 400;
  }
  strong {
    color: var(--sixth);
    font-weight: 700;
    font-size: 1.2rem;
  }
`;

const StyledPopupButton = styled(PopupButton)`
  background: var(--linearGradient);
  color: var(--white);
  padding: 0.5rem 1rem;
  border: 0;
  font-weight: 700;
  width: initial;
  text-decoration: underline;
  display: initial;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 8px 16px rgb(0 0 0 / 40%);
  border-radius: 0.25rem;
  margin: 1rem 0 2rem;

  @media (max-width: 720px) {
    width: 100%;
    display: block;
    margin: 1rem auto;
  }
`;

const months: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const AnalyticsBoxTitleWithYoY = styled(AnalyticsBoxTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border: none;
`;

const YoYChange = styled.span<{ $isPositive: boolean }>`
  color: ${(props) => (props.$isPositive ? "green" : "red")};
  font-size: 0.9em;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const YoYChangeTitle = styled.span`
  font-size: 0.8em;
  color: var(--fourth);
  margin-bottom: 2px;
`;

const sortChronologically = (
  dataForSorting: Record<string, number | string>
) => {
  // Create a new object without 'yoy_change' and 'market' properties
  const filteredData = Object.fromEntries(
    Object.entries(dataForSorting).filter(
      ([key]) => key !== "yoy_change" && key !== "market"
    )
  );

  return Object.fromEntries(
    Object.entries(filteredData).sort(
      ([a], [b]) => new Date(a).getTime() - new Date(b).getTime()
    )
  );
};

const FullHeightAnalyticsBox = styled(AnalyticsBox)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

const FullHeightChartWrapper = styled(ChartWrapper)`
  flex-grow: 1;
  height: 200px; // Adjust this value as needed
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Modify the orderBedroomData function to work with RateData
const orderBedroomData = (data: RateData): Record<string, number> => {
  const order = [
    "Studio",
    "1 Bedroom",
    "2 Bedroom",
    "3 Bedroom",
    "4 Bedroom",
    "5 Bedroom"
  ];
  return Object.fromEntries(
    order
      .map((key) => [key, data[key]])
      .filter(([_, value]) => value !== undefined)
  );
};

// Add this function to format numbers with commas
const formatNumberWithCommas = (num: number) => {
  return num
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const AnalyzeButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--black);
  color: var(--white);
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-weight: 600;
  margin-top: 1rem;
  transition: background-color 0.2s ease;

  &:hover {
    background: var(--linearGradient);
  }

  &::after {
    content: " →";
    margin-left: 0.5rem;
  }
`;

const RentalRegulationButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: var(--quinary);
  border: 1px solid var(--quinary);
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-weight: 600;
  margin-top: 1rem;
  margin-left: 1rem;
  transition: background-color 0.2s ease;

  &:hover {
    background: rgba(var(--quinary-rgb), 0.1);
  }

  &::after {
    content: " →";
    margin-left: 0.5rem;
    color: var(--quinary);
  }
`;

// Add this new styled component
const FullHeightAnalyticsBoxWithTitle = styled(FullHeightAnalyticsBox)`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

const AnalyticsContent = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const AnalyticsRowsContainer = styled.div<{ $fullWidth?: boolean }>`
  width: ${(props) => (props.$fullWidth ? "100%" : "50%")};
  padding-right: ${(props) => (props.$fullWidth ? "0" : "1rem")};
  border-right: ${(props) =>
    props.$fullWidth ? "none" : "1px solid var(--primary)"};

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`;

const MapContainer = styled.div`
  width: 50%;
  height: 80vh;
  position: fixed;
  top: 153px;
  right: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const libraries: any[] = ["places"];

// Add this type definition
type PolygonData = {
  paths: { lat: number; lng: number }[];
  zipcode: string;
};

// Add this custom map style
const simplifiedMapStyle = [
  {
    featureType: "road",
    elementType: "labels",
    stylers: [{ visibility: "off" }]
  },
  // {
  //   featureType: "poi",
  //   stylers: [{ visibility: "off" }]
  // },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [{ visibility: "off" }]
  },
  // {
  //   featureType: "road",
  //   stylers: [{ visibility: "off" }]
  // },
  {
    featureType: "landscape",
    stylers: [{ color: "#FFFBF3" }]
  },
  {
    featureType: "water",
    stylers: [{ color: "#EEEFF8" }]
  }
];

// Add this new styled component for the table
const ZipcodeTable = styled(Table)`
  width: 100%;
  margin-top: 1rem;
`;

// Add this new styled component for the mini table
const MiniZipcodeTable = styled(Table)`
  width: 100%;
  // margin-top: 0.5rem;
  padding: 0;
  font-size: 0.9em;
  table-layout: fixed; // Add this line to ensure equal column widths
`;

const TableCellContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Add this new styled component for clickable zipcodes
const ZipcodeLink = styled.a`
  color: var(--quinary);
  font-weight: 400;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;

// Add this new styled component for the circle
const YieldCircle = styled.div`
  background: var(--white);
  border-radius: 50%;
  color: var(--primary);
  text-align: center;
  font-size: 8px;
  font-weight: 700;
  height: 30px;
  // text-decoration: underline;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%);
`;
// Add this new styled component near the other styled components
const HoverTooltip = styled.div`
  background: white;
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  z-index: 1000;
  pointer-events: none;
`;

// Add this new styled component near your other styled components
const MetricDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 100%;

  .metric-value {
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary);
  }

  .metric-label {
    font-size: 1.8rem;
    text-align: center;
    color: var(--primary);
    margin-top: 0.5rem;
  }
  .metric-sub-label {
    font-size: 1rem;
    text-align: center;
    color: var(--secondary);
    text-decoration: underline;
    // margin-top: 0.5rem;
  }
`;

// Add this near other MetricDisplay styled component
const MetricDisplayWithTooltip = styled(MetricDisplay)`
  .metric-sub-label {
    cursor: help;
    text-decoration: underline dotted;
  }
`;

// Add these new styled components near your other styled components
const MapOverlay = styled.div`
  position: absolute;
  bottom: 50px;
  left: 24px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const OverlayTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: var(--primary);
  margin: 0 0 16px 0;
  text-align: center;
`;

const OverlayButton = styled.button`
  display: block;
  width: 100%;
  padding: 12px 24px;
  margin: 8px 0;
  border: 2px solid var(--primary);
  border-radius: 4px;
  background: white;
  color: var(--primary);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
  position: relative;

  &:after {
    content: "→";
    position: absolute;
    right: 16px;
  }

  &:hover {
    background: var(--primary);
    color: white;
  }
`;

// Add this utility function near the top of the file, after the imports
const isBot = () => {
  const botPatterns = [
    "bot",
    "spider",
    "crawler",
    "googlebot",
    "bingbot",
    "yandex",
    "baidu",
    "duckduckbot",
    "slurp",
    "mediapartners-google",
    "lighthouse"
  ];

  const userAgent = navigator.userAgent.toLowerCase();
  return botPatterns.some((pattern) => userAgent.includes(pattern));
};

export default function AnalyticsMarket() {
  const { market } = useParams<Record<string, string>>();
  const { user } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [ratingHeight, setRatingHeight] = React.useState(0);
  const [ratingWrapperWidth, setRatingWrapperWidth] = React.useState(0);
  const [showZipcodeModal, setShowZipcodeModal] = useState(false);

  const urlSlug = React.useMemo(
    () =>
      market
        ? `https://www.getchalet.com/analytics/overview/${market}`
        : "https://www.getchalet.com/analytics/overview/",
    [market]
  );

  const { data, isLoading } = useSWR<MarketData>(
    `https://zmura59afa.execute-api.us-west-2.amazonaws.com/v1/market/${market}`,
    async (url: string) => {
      const response = await fetcher(url);
      if (response.message === "Data not found in DynamoDB") {
        // Redirect to overview page if data not found
        history.push("/analytics/overview");
        return null;
      }
      return response;
    }
  );

  let currentDate = new Date();
  let currentMonth = new Date();
  const currentYear = currentDate.getFullYear();
  const hostData: Record<string, any> = {};

  if (data) {
    const splitUpdated = data.updated.split(" ");
    currentDate = new Date();
    currentDate.setMonth(
      months.findIndex((month) => month === splitUpdated[0])
    );
    currentDate.setFullYear(Number(splitUpdated[1]));
    currentMonth = new Date(currentDate.getTime());
    currentMonth.setMonth(currentMonth.getMonth() - 0);

    data.host_info.forEach(({ host_name, total_listings }) => {
      hostData[host_name] = total_listings;
    });
  }

  const setRef = React.useCallback((node) => {
    if (node) {
      const { height, width } = node.getBoundingClientRect();
      setRatingHeight(height);
      setRatingWrapperWidth(
        node.parentNode.getBoundingClientRect().width - width
      );
    }
  }, []);
  // Near the top of the component, after data is fetched:
  const currentMonthForTitle = months[new Date().getMonth()];

  // Update the RateData interface to include all bedroom types

  // Define the bedroom types
  const bedroomTypes = [
    "Studio",
    "1 Bedroom",
    "2 Bedroom",
    "3 Bedroom",
    "4 Bedroom",
    "5 Bedroom"
  ] as const;
  type BedroomType = typeof bedroomTypes[number];

  // Add these styled components
  const StyledTable = styled(Table)`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  `;

  const StyledTableHeader = styled(TableHeader)`
    font-weight: 400;
    // background: var(--lightPurple);
    width: 100%;
  `;

  const StyledTableCell = styled(TableCell)`
    border-right: 1px solid var(--primary);
    padding: 8px;
    font-size: 1.1rem;
    // width: 150px;

    &:last-child {
      border-right: none;
    }
  `;

  const StyledTableHeaderCell = styled(StyledTableCell)`
    font-weight: 400;
  `;
  // Add these new styled components
  const TableHeaderCell = styled(StyledTableHeaderCell)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px 4px;
    font-size: 1.1rem;
  `;

  const StyledAnnualRevenueCell = styled(StyledTableCell)`
    font-weight: 700;
    color: var(--primary);
  `;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_PLACES_API_KEY || "",
    libraries
    // Don't load the script for bots
    // skip: isBot()
  });

  const mapCenter = { lat: 39.740063, lng: -104.939545 }; // Default to Denver, CO

  // Use useMemo to create the color function only when data is available
  const getColorForYield = useMemo(() => {
    if (!data || !data.zipcode_data) {
      console.log("No data available");
      return () => "rgba(200, 200, 200, 0.5)";
    }

    const yields = data.zipcode_data.map(
      (item: any) => item.median_gross_yield
    );
    console.log("yields", yields);
    if (yields.length === 1) {
      console.log("returning quinary as yields length is 1");
      return () => `rgba(${Colors.sixthRgb}, 0.5)`;
    }
    const minYield = Math.min(...yields);
    const maxYield = Math.max(...yields);

    console.log("Yields range:", minYield, "to", maxYield);

    return (zipcode: string) => {
      console.log("zipcode", zipcode);
      const zipcodeData = data.zipcode_data.find(
        (item: any) => item.zipcode === zipcode
      );
      if (!zipcodeData) {
        console.log(`No data found for zipcode: ${zipcode}`);
        return "rgba(200, 200, 200, 0.5)";
      }

      const grossYield = parseFloat(zipcodeData.median_gross_yield);
      if (typeof grossYield !== "number" || Number.isNaN(grossYield)) {
        console.log(`Invalid gross yield for zipcode ${zipcode}:`, grossYield);
        console.log("type of gross yield", typeof grossYield);
        return "rgba(255, 0, 0, 0.5)"; // Red color for invalid data
      }

      const normalizedYield = (grossYield - minYield) / (maxYield - minYield);
      const opacity = Math.max(0.1, Math.min(0.9, normalizedYield));

      const color = Colors.sixthRgb;

      console.log(
        `Zipcode: ${zipcode}, Gross Yield: ${grossYield}, Normalized: ${normalizedYield}, Color: rgba(${color}, ${opacity})`
      );

      return `rgba(${color}, ${opacity})`;
    };
  }, [data]);

  // Add this handler for zipcode navigation
  const handleZipcodeNavigation = useCallback(
    (zipcode: string) => {
      if (user) {
        history.push(`/analytics/overview/${market}/${zipcode}`);
      } else {
        setShowZipcodeModal(true);
      }
    },
    [user, market, history]
  );

  // Update polygon click handler
  const handlePolygonClick = useCallback(
    (zipcode: string) => {
      handleZipcodeNavigation(zipcode);
    },
    [handleZipcodeNavigation]
  );

  // Update the map options
  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: true,
    fullscreenControl: false,
    styles: simplifiedMapStyle // Add this line
  };

  // Function to calculate the center of a polygon
  const getPolygonCenter = (paths: { lat: number; lng: number }[]) => {
    const latSum = paths.reduce((sum, point) => sum + point.lat, 0);
    const lngSum = paths.reduce((sum, point) => sum + point.lng, 0);
    return {
      lat: latSum / paths.length,
      lng: lngSum / paths.length
    };
  };

  // Use the zipcode_polygons from the API response
  const polygons = data?.zipcode_polygons || [];

  // Use useMemo to create the getGrossYield function only when data is available
  const getGrossYield = useMemo(() => {
    if (!data || !data.zipcode_data) {
      return () => "N/A";
    }
    console.log("data", data);

    return (zipcode: string) => {
      const zipcodeData = data.zipcode_data.find(
        (item: any) => item.zipcode === zipcode
      );
      if (
        zipcodeData &&
        typeof parseFloat(zipcodeData.median_gross_yield) === "number" &&
        !Number.isNaN(parseFloat(zipcodeData.median_gross_yield))
      ) {
        return (parseFloat(zipcodeData.median_gross_yield) * 100).toFixed(2);
      }
      return "N/A";
    };
  }, [data]);

  // Update the shouldShowMap function to also check for bots
  const shouldShowMap = useMemo(() => {
    if (isBot() || !data?.zipcode_polygons) return false;
    return data.zipcode_polygons.some(
      (polygon) => polygon.paths && polygon.paths.length > 0
    );
  }, [data?.zipcode_polygons]);

  // Add these new state variables after other state declarations
  const [hoveredZipcode, setHoveredZipcode] = React.useState<string | null>(
    null
  );
  const [mousePosition, setMousePosition] = React.useState<{
    x: number;
    y: number;
  } | null>(null);

  // Add this function near other helper functions
  const getPolygonHoverStyle = useCallback(
    (zipcode: string) => ({
      fillColor: getColorForYield(zipcode),
      fillOpacity: hoveredZipcode === zipcode ? 1 : 1,
      strokeColor:
        hoveredZipcode === zipcode ? Colors.eighth : "var(--seventh)",
      strokeOpacity: 1,
      strokeWeight: hoveredZipcode === zipcode ? 3 : 1,
      clickable: true,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: hoveredZipcode === zipcode ? 2 : 1
    }),
    [hoveredZipcode, getColorForYield]
  );

  // Create a new styled component for the top row boxes
  const TopRowBox = styled(AnalyticsBox)`
    height: 150px; // Fixed height for both boxes
    margin-bottom: 1rem; // Space before unemployment chart
  `;

  return (
    <AnalyticsWrapper style={{ minHeight: "100vh" }}>
      <Helmet>
        <title>
          {`${currentMonthForTitle} ${currentYear} ${
            data?.market || "Market"
          } Airbnb Analytics & Demand Data`}
        </title>
        <meta
          name="description"
          content={`Airbnb Rental Market Analysis Update for ${
            data?.market || "the selected market"
          }. Annual rental income of ${currencyNotation(
            data?.annual_revenue || 0,
            0
          )}, occupancy rate of ${percentNotation(
            (data?.occupancy_rate.market || 0) / 100
          )}, and average daily rate of ${currencyNotation(
            data?.average_daily_rate.market || 0,
            0
          )}`}
        />
        <link rel="canonical" href={urlSlug} />
        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content={`${data?.market || "Market"} Analytics | Chalet`}
        />
        <meta
          property="og:description"
          content={`Airbnb Rental Market Update for ${
            data?.market || "the selected market"
          }. Annual rental income of ${currencyNotation(
            data?.annual_revenue || 0,
            0
          )}, occupancy rate of ${percentNotation(
            (data?.occupancy_rate.market || 0) / 100
          )}, and average daily rate of ${currencyNotation(
            data?.average_daily_rate.market || 0,
            0
          )}`}
        />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.getchalet.com/images/analytics_dashboard.webp"
        />{" "}
        {/* Replace with actual image URL */}
        <meta property="og:site_name" content="Chalet" />
        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${data?.market || "Market"} Analytics | Chalet`}
        />
        <meta
          name="twitter:description"
          content={`Airbnb Rental Market Update for ${
            data?.market || "the selected market"
          }. View Airbnb rental market data, including income potential, occupancy rates, and more.`}
        />
        <meta
          name="twitter:image"
          content="https://www.getchalet.com/og-image.jpg"
        />{" "}
        {/* Replace with actual image URL */}
      </Helmet>
      <AnalyticsHeader />
      {isLoading && (
        <AnalyticsWrapper marginTop={false}>
          <SkeletonLoader />
        </AnalyticsWrapper>
      )}
      {!isLoading && data === undefined && (
        <AnalyticsWrapper>
          <Flex
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%"
            }}
          >
            No data.
          </Flex>
        </AnalyticsWrapper>
      )}
      {!isLoading && data !== undefined && (
        <AnalyticsContent>
          <AnalyticsRowsContainer $fullWidth={!shouldShowMap}>
            <AnalyticRow>
              <AnalyticSection $column style={{ gridArea: "charts" }}>
                <AnalyticsSplitWrapper>
                  <AnalyticSection $third>
                    <FullHeightAnalyticsBox>
                      {/* <AnalyticsBoxTitle>Annual Revenue</AnalyticsBoxTitle> */}
                      <FullHeightChartWrapper>
                        <MetricDisplayWithTooltip>
                          <div className="metric-label">Annual Revenue</div>
                          <div className="metric-value">
                            {currencyNotation(data.annual_revenue, 0)}
                          </div>
                          <Tooltip
                            content="Full-time rentals are properties that are available for booking more than 180 days per year"
                            contentStyle={{
                              transform: "translateX(5%)"
                            }}
                          >
                            <div className="metric-sub-label">
                              Based on{" "}
                              {formatNumberWithCommas(data.supply.market)}{" "}
                              full-time Airbnb rentals
                            </div>
                          </Tooltip>
                        </MetricDisplayWithTooltip>
                      </FullHeightChartWrapper>
                    </FullHeightAnalyticsBox>
                  </AnalyticSection>
                  <AnalyticSection $third>
                    <FullHeightAnalyticsBox>
                      <AnalyticsBoxTitle>
                        <div>Average Daily Rate</div>
                        <div className="analytics-box-title-subtitle">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 0
                          }).format(data.average_daily_rate.market)}
                        </div>
                      </AnalyticsBoxTitle>
                      <FullHeightChartWrapper>
                        <BarChart
                          data={orderBedroomData(data.average_daily_rate)}
                          yGrid
                          xAxis
                          color={Colors.sixth}
                        />
                      </FullHeightChartWrapper>
                    </FullHeightAnalyticsBox>
                  </AnalyticSection>
                  <AnalyticSection $third>
                    <FullHeightAnalyticsBox $knockoutDarkBlue>
                      <GrossYield
                        grossYield={data.gross_yield}
                        ranking={data.us_gross_yield_ranking}
                      />
                    </FullHeightAnalyticsBox>
                  </AnalyticSection>
                </AnalyticsSplitWrapper>
              </AnalyticSection>
            </AnalyticRow>
            <AnalyticRow>
              <AnalyticsSplitWrapper>
                <AnalyticSection style={{ flex: "1 1 40%" }}>
                  <AnalyticsTextWrapper>
                    <Markdown>
                      {Object.values(data.row_one_text).join("\n\n")}
                    </Markdown>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem"
                      }}
                    >
                      <AnalyzeButton href="/analytics/str-calculator">
                        Analyze Properties in {data.market}
                      </AnalyzeButton>
                      <RentalRegulationButton
                        href={`/analytics/rental-regulations/${market}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Rental Regulation
                      </RentalRegulationButton>
                    </div>
                  </AnalyticsTextWrapper>
                </AnalyticSection>
                <AnalyticSection style={{ flex: "1 1 60%" }}>
                  <FullHeightAnalyticsBoxWithTitle>
                    <AnalyticsBoxTitle>
                      Top Submarkets in {data.market}
                    </AnalyticsBoxTitle>
                    <MiniZipcodeTable>
                      <StyledTableHeader>
                        <TableRow>
                          <TableHeaderCell as="th" $longCell>
                            Zipcode
                          </TableHeaderCell>
                          <TableHeaderCell as="th" $longCell>
                            Gross Yield
                          </TableHeaderCell>
                          <TableHeaderCell as="th" $longCell>
                            Annual Revenue
                          </TableHeaderCell>
                          <TableHeaderCell as="th" $longCell>
                            Full-Time Listings
                          </TableHeaderCell>
                          <TableHeaderCell as="th" $longCell>
                            Zillow Home Value
                          </TableHeaderCell>
                        </TableRow>
                      </StyledTableHeader>
                      <TableBody style={{ width: "100%" }}>
                        {data.zipcode_data
                          .sort(
                            (a: any, b: any) =>
                              b.median_gross_yield - a.median_gross_yield
                          )
                          .slice(0, 5)
                          .map((item: any) => (
                            <TableRow key={item.zipcode}>
                              <StyledTableCell $longCell>
                                <TableCellContent>
                                  <ZipcodeLink
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleZipcodeNavigation(item.zipcode);
                                    }}
                                    href="#"
                                  >
                                    {item.zipcode}
                                  </ZipcodeLink>
                                </TableCellContent>
                              </StyledTableCell>
                              <StyledTableCell $longCell>
                                <TableCellContent>
                                  {percentNotation(item.median_gross_yield)}
                                </TableCellContent>
                              </StyledTableCell>
                              <StyledTableCell $longCell>
                                <TableCellContent>
                                  {currencyNotation(
                                    item.median_annual_revenue,
                                    0
                                  )}
                                </TableCellContent>
                              </StyledTableCell>
                              <StyledTableCell $longCell>
                                <TableCellContent>
                                  {formatNumberWithCommas(
                                    item.total_full_time_listings
                                  )}
                                </TableCellContent>
                              </StyledTableCell>
                              <StyledTableCell $longCell>
                                <TableCellContent>
                                  {compactCurrencyNotation(
                                    item.zillow_average_home_price,
                                    1
                                  )}
                                </TableCellContent>
                              </StyledTableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </MiniZipcodeTable>
                  </FullHeightAnalyticsBoxWithTitle>
                </AnalyticSection>
              </AnalyticsSplitWrapper>
            </AnalyticRow>
            <AnalyticRow>
              <AnalyticSection>
                <FullHeightAnalyticsBox>
                  <AnalyticsBoxTitle>
                    <div>
                      Occupancy Rate
                      {/* {` (${
                        months[currentMonth.getMonth()]
                      } ${currentMonth.getFullYear()})`} */}
                    </div>
                    <div className="analytics-box-title-subtitle">
                      {percentNotation(data.occupancy_rate.market / 100)}
                    </div>
                  </AnalyticsBoxTitle>
                  <FullHeightChartWrapper>
                    <BarChart
                      data={orderBedroomData(data.occupancy_rate)}
                      color={Colors.sixth}
                      percent
                      yGrid
                      xAxis
                    />
                  </FullHeightChartWrapper>
                </FullHeightAnalyticsBox>
              </AnalyticSection>
              <AnalyticSection>
                <FullHeightAnalyticsBox>
                  <AnalyticsBoxTitle>
                    <div>Active Short-Term Rentals</div>
                    <div className="analytics-box-title-subtitle">
                      {formatNumberWithCommas(data.supply.market)}
                    </div>
                  </AnalyticsBoxTitle>
                  <FullHeightChartWrapper>
                    <BarChart
                      data={orderBedroomData(data.supply)}
                      horizontal
                      color={Colors.fourth}
                      yGrid
                      xAxis
                      yAxisStyle={{
                        color: Colors.primary,
                        fontWeight: "normal"
                      }}
                    />
                  </FullHeightChartWrapper>
                </FullHeightAnalyticsBox>
              </AnalyticSection>
            </AnalyticRow>
            <AnalyticRow>
              <AnalyticSection $column $third>
                <AnalyticsTextWrapper>
                  <Markdown>
                    {Object.values(data.row_two_text).join("\n\n")}
                  </Markdown>
                </AnalyticsTextWrapper>
              </AnalyticSection>
            </AnalyticRow>
            <AnalyticRow>
              <AnalyticSection>
                <AnalyticsBox>
                  <AnalyticsBoxTitleWithYoY>
                    <div>
                      <div style={{ fontSize: "1.1rem" }}>
                        Zillow Home Value
                      </div>
                      <div className="analytics-box-title-subtitle">
                        {compactCurrencyNotation(
                          data?.zillow_home_value_data?.market || 0,
                          1
                        )}
                      </div>
                    </div>
                    {data.zillow_home_value_data.yoy_change && (
                      <YoYChange
                        $isPositive={data.zillow_home_value_data.yoy_change > 0}
                      >
                        <YoYChangeTitle>YoY Change</YoYChangeTitle>
                        {data.zillow_home_value_data.yoy_change > 0 ? "+" : ""}
                        {data.zillow_home_value_data.yoy_change}%
                      </YoYChange>
                    )}
                  </AnalyticsBoxTitleWithYoY>
                  <FullHeightChartWrapper
                    style={{ height: shouldShowMap ? "200px" : "auto" }}
                  >
                    {data.zillow_home_value_data && (
                      <AreaChart
                        data={
                          sortChronologically(
                            data.zillow_home_value_data
                          ) as any
                        }
                        min={null}
                      />
                    )}
                  </FullHeightChartWrapper>
                </AnalyticsBox>
              </AnalyticSection>
              <AnalyticSection>
                <FullHeightAnalyticsBox>
                  <AnalyticsBoxTitle>
                    <div>Host Info</div>
                    <div>Listings</div>
                    <div>Average Rating</div>
                  </AnalyticsBoxTitle>
                  <Flex style={{ width: "100%" }}>
                    <ChartWrapper>
                      {ratingHeight && (
                        <BarChart
                          sort="values"
                          height={ratingHeight}
                          width={ratingWrapperWidth}
                          data={hostData}
                          dataType="number"
                          color={Colors.primary}
                          datalabels={false}
                          horizontal
                          padding={{
                            top: 0,
                            bottom: 0
                          }}
                          yAxisStyle={{
                            color: Colors.primary,
                            fontWeight: "normal"
                          }}
                        />
                      )}
                    </ChartWrapper>
                    <Flex
                      style={{
                        flex: "1 1 30%",
                        flexDirection: "column",
                        padding: "0.5rem 0"
                      }}
                      ref={setRef}
                    >
                      {data.host_info.map(({ host_name, avg_rating }) => (
                        <Flex key={host_name}>
                          <Star
                            style={{
                              width: "1rem",
                              fill: "var(--linearGradient)"
                            }}
                          />
                          {numberNotation(avg_rating, 2)}
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </FullHeightAnalyticsBox>
              </AnalyticSection>
            </AnalyticRow>
            <AnalyticRow>
              <AnalyticsBox>
                <AnalyticsBoxTitle>
                  <div>Most Impactful Amenities</div>
                  <div className="analytics-box-title-subtitle">
                    <div
                      style={{
                        background: "var(--seventh)",
                        color: "var(--white)",
                        padding: "0.25rem 0.5rem",
                        borderRadius: "0.25rem"
                      }}
                    >
                      Beta
                    </div>
                  </div>
                </AnalyticsBoxTitle>
                <ChartWrapper>
                  <BarChart
                    aspectRatio={1}
                    data={data.most_impactful_amenities as any}
                    horizontal
                    color={Colors.fourth}
                    dataType="currency"
                    stacked
                    datalabels
                    isAmenitiesChart
                    yAxisStyle={{
                      color: Colors.primary,
                      fontWeight: "normal"
                    }}
                  />
                </ChartWrapper>
              </AnalyticsBox>
            </AnalyticRow>
            <AnalyticRow>
              <AnalyticsBox>
                <AnalyticsBoxTitle>
                  <div>Where Airbnb Guests Are Coming From</div>
                </AnalyticsBoxTitle>
                <ChartWrapper>
                  <BarChart
                    sort="values"
                    aspectRatio={1}
                    data={data.demand_source as any}
                    percent
                    color={Colors.sixth}
                    // horizontal
                    datalabels={false}
                    yAxis
                    xAxis
                    // yGrid
                    // xAxisPosition="top"
                    yAxisStyle={{
                      color: Colors.primary,
                      fontWeight: "normal"
                    }}
                  />
                </ChartWrapper>
              </AnalyticsBox>
              <AnalyticsBox $split>
                <AnalyticsBoxTitleWithYoY>
                  <div>
                    <div>Unemployment Rate</div>
                    <div className="analytics-box-title-subtitle">
                      <div>{data.unemployment_rate.market}</div>
                    </div>
                  </div>
                  {data.unemployment_rate.yoy_change && (
                    <YoYChange
                      $isPositive={
                        parseFloat(data.unemployment_rate.yoy_change) < 0
                      }
                    >
                      <YoYChangeTitle>YoY Change</YoYChangeTitle>
                      {parseFloat(data.unemployment_rate.yoy_change) > 0
                        ? "+"
                        : ""}
                      {data.unemployment_rate.yoy_change}
                    </YoYChange>
                  )}
                </AnalyticsBoxTitleWithYoY>
                <ChartWrapper $textBlue>
                  <AreaChart
                    color={Colors.primary}
                    data={sortChronologically(data.unemployment_rate) as any}
                    min={null}
                    y
                    percent
                    yAxisPrecision={1}
                    yAxisTickCount={5}
                  />
                </ChartWrapper>
              </AnalyticsBox>
            </AnalyticRow>
            <AnalyticRow>
              <AnalyticSection $column>
                <AnalyticsBox>
                  <AnalyticsBoxTitle>Property Tax</AnalyticsBoxTitle>
                  <ChartWrapper $text>
                    {percentNotation(data.property_tax, 2)}
                  </ChartWrapper>
                </AnalyticsBox>
              </AnalyticSection>
              <AnalyticSection $column>
                <AnalyticsBox>
                  <AnalyticsBoxTitle>Airbnb Regulation</AnalyticsBoxTitle>
                  <ChartWrapper $textGreen>
                    <a
                      href={`/rental-regulations/${market}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      {data["airbnb-regulation"]
                        .split("-")
                        .map((str) => str[0].toUpperCase() + str.slice(1))
                        .join(" ")}
                    </a>
                  </ChartWrapper>
                </AnalyticsBox>
              </AnalyticSection>
            </AnalyticRow>
            <AnalyticRow>
              <AnalyticSection $column>
                <AnalyticsTextWrapper>
                  <Markdown>
                    {Object.values(data.row_three_text).join("\n\n")}
                  </Markdown>
                </AnalyticsTextWrapper>
                <AnalyticsBox style={{ marginBottom: "1rem", padding: 0 }}>
                  <AnalyticsBoxTitle>
                    Annual Revenue and Occupancy Rates by Bedroom Count
                  </AnalyticsBoxTitle>
                  <StyledTable>
                    <StyledTableHeader>
                      <TableRow>
                        <StyledTableHeaderCell as="th" $longCell>
                          Bedrooms
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell as="th" $longCell>
                          Listings
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell as="th" $longCell>
                          Occupancy Rate
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell as="th" $longCell>
                          Average Daily Rate
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell as="th" $longCell>
                          Annual Revenue
                        </StyledTableHeaderCell>
                      </TableRow>
                    </StyledTableHeader>
                    <TableBody>
                      {bedroomTypes.map((bedroom) => (
                        <TableRow key={bedroom}>
                          <StyledTableCell $longCell>{bedroom}</StyledTableCell>
                          <StyledTableCell $longCell>
                            {formatNumberWithCommas(data.supply[bedroom])}
                          </StyledTableCell>
                          <StyledTableCell $longCell>
                            {percentNotation(
                              data.occupancy_rate[bedroom] / 100
                            )}
                          </StyledTableCell>
                          <StyledTableCell $longCell>
                            {currencyNotation(
                              data.average_daily_rate[bedroom],
                              0
                            )}
                          </StyledTableCell>
                          <TableCell $longCell>
                            {currencyNotation(
                              data.average_daily_rate[bedroom] *
                                (data.occupancy_rate[bedroom] / 100) *
                                365,
                              0
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </AnalyticsBox>

                <AnalyticsBox
                  $decorative
                  style={{
                    alignItems: "flex-start",
                    flex: "1 1 auto"
                  }}
                >
                  <Header
                    size={4}
                    style={{ alignItems: "flex-start", textAlign: "left" }}
                  >
                    Real Estate Agents With
                    <br />
                    Airbnb Rental Investing Experience
                  </Header>
                  <div style={{ padding: "0 1rem", fontSize: "0.875rem" }}>
                    Let our agents guide you to{" "}
                    <strong style={{ color: "var(--primary)" }}>
                      the best short-term rental
                    </strong>{" "}
                    investments in {LiveMarkets[market]}
                  </div>
                  <div style={{ padding: "0 1rem" }}>
                    <StyledPopupButton
                      id="DU51pTsh"
                      // open="load"
                      // data-tf-lazy="false"
                      shareGaInstance="true"
                      hidden={{
                        market_of_interest:
                          LiveMarkets[market] || "Not Specified",
                        source_page: urlSlug
                      }}
                      className="connect-with-realtor_button"
                      data-id="connect-with-realtor_button"
                    >
                      Connect with Airbnb Realtor
                    </StyledPopupButton>
                  </div>
                </AnalyticsBox>
              </AnalyticSection>
            </AnalyticRow>
          </AnalyticsRowsContainer>

          {shouldShowMap && (
            <MapContainer>
              {isLoaded && data && !isLoading && (
                <>
                  <GoogleMap
                    mapContainerStyle={{
                      width: "100%",
                      height: "100%"
                    }}
                    center={data.map_center}
                    zoom={11}
                    options={mapOptions}
                  >
                    {data.zipcode_polygons.map((polygonData) => (
                      <React.Fragment key={polygonData.zipcode}>
                        <Polygon
                          paths={polygonData.paths}
                          options={getPolygonHoverStyle(polygonData.zipcode)}
                          onClick={() =>
                            handlePolygonClick(polygonData.zipcode)
                          }
                          onMouseOver={() => {
                            setHoveredZipcode(polygonData.zipcode);
                          }}
                          onMouseOut={() => {
                            setHoveredZipcode(null);
                            setMousePosition(null);
                          }}
                          onMouseMove={(e: google.maps.PolyMouseEvent) => {
                            if (e.domEvent instanceof MouseEvent) {
                              setMousePosition({
                                x: e.domEvent.clientX,
                                y: e.domEvent.clientY
                              });
                            }
                          }}
                        />
                        {/* <OverlayView
                          position={getPolygonCenter(polygonData.paths)}
                          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        >
                          <YieldCircle>
                            Gross Yield {getGrossYield(polygonData.zipcode)}%
                          </YieldCircle>
                        </OverlayView> */}
                      </React.Fragment>
                    ))}
                  </GoogleMap>
                  {hoveredZipcode && mousePosition && (
                    <HoverTooltip
                      style={{
                        position: "fixed",
                        left: mousePosition.x + 15,
                        top: mousePosition.y + 15
                      }}
                    >
                      <div>
                        <strong>Zipcode:</strong> {hoveredZipcode}
                      </div>
                      <div>
                        <strong>Gross Yield:</strong>{" "}
                        {getGrossYield(hoveredZipcode)}%
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          marginTop: "4px",
                          opacity: 0.8
                        }}
                      >
                        Click for details
                      </div>
                    </HoverTooltip>
                  )}
                  <MapOverlay>
                    <OverlayTitle>
                      Get matched with an
                      <br />
                      Airbnb focused
                      <br />
                      agent in {data.market}
                    </OverlayTitle>
                    <PopupButton
                      id="DU51pTsh"
                      shareGaInstance="true"
                      className="connect-with-realtor_button"
                      hidden={{
                        market_of_interest:
                          LiveMarkets[market] || "Not Specified",
                        source_page: urlSlug,
                        user_intent: "buying"
                      }}
                      style={{
                        display: "block",
                        width: "100%",
                        padding: "12px 24px",
                        margin: "8px 0",
                        border: "2px solid var(--primary)",
                        borderRadius: "4px",
                        background: "white",
                        color: "var(--primary)",
                        fontWeight: 600,
                        cursor: "pointer",
                        transition: "all 0.2s ease",
                        textAlign: "center",
                        position: "relative"
                      }}
                    >
                      I&apos;m buying
                    </PopupButton>
                    <PopupButton
                      id="DU51pTsh"
                      shareGaInstance="true"
                      className="connect-with-realtor_button"
                      hidden={{
                        market_of_interest:
                          LiveMarkets[market] || "Not Specified",
                        source_page: urlSlug,
                        user_intent: "selling"
                      }}
                      style={{
                        display: "block",
                        width: "100%",
                        padding: "12px 24px",
                        margin: "8px 0",
                        border: "2px solid var(--primary)",
                        borderRadius: "4px",
                        background: "white",
                        color: "var(--primary)",
                        fontWeight: 600,
                        cursor: "pointer",
                        transition: "all 0.2s ease",
                        textAlign: "center",
                        position: "relative"
                      }}
                    >
                      I&apos;m selling
                    </PopupButton>
                    <PopupButton
                      id="M700RBmq"
                      shareGaInstance="true"
                      className="connect-with-str-lender_button"
                      hidden={{
                        market_of_interest:
                          LiveMarkets[market] || "Not Specified",
                        source_page: urlSlug,
                        user_intent: "selling"
                      }}
                      style={{
                        display: "block",
                        width: "100%",
                        padding: "12px 24px",
                        margin: "8px 0",
                        border: "2px solid var(--primary)",
                        borderRadius: "4px",
                        background: "white",
                        color: "var(--primary)",
                        fontWeight: 600,
                        cursor: "pointer",
                        transition: "all 0.2s ease",
                        textAlign: "center",
                        position: "relative"
                      }}
                    >
                      I&apos;m financing
                    </PopupButton>
                  </MapOverlay>
                </>
              )}
            </MapContainer>
          )}
        </AnalyticsContent>
      )}
      <AnalyticsModal suppressTimeout={showZipcodeModal} />
      <ZipcodeModal
        isOpen={showZipcodeModal}
        toggle={() => setShowZipcodeModal(false)}
      />
    </AnalyticsWrapper>
  );
}
