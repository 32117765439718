import React, { useEffect, useRef } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import ahoy from "ahoy.js";
import Home from "./pages/Home";
// import Home2 from "./pages/Home2";
import Blog from "./pages/Blog";
import InvestorGuides from "./pages/InvestorGuides";
import BestAirbnbs from "./pages/BestAirbnbs";
import RentalRegulations from "./pages/RentalRegulations";
import PropertyManagement from "./pages/locations/PropertyManagement";
import ChaletRemoteHost from "./pages/ChaletRemoteHost";
import STRResources from "./pages/STRResources";
import TurnoverServices from "./pages/TurnoverServices";
import ListingTitleGenerator from "./pages/ListingTitleGenerator";
import CostSegCalculator from "./pages/CostSegCalculator";
import Service1031Exchange from "./pages/1031Exchange";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import StrCalculator from "./pages/StrCalculator";
import DSCRCalculator from "./pages/DSCRCalculator";
import NotFound from "./pages/NotFound";
import ChaletConcierge from "./pages/ChaletConcierge";
import CostSegregation from "./pages/locations/CostSegregation";
import DownloadWhitepaper from "./pages/DownloadWhitepaper";
import DownloadTopMarkets from "./pages/DownloadTopMarkets";
import DownloadReportV2 from "./pages/DownloadReportV2";
import STRUnderwritingTool from "./pages/STRUnderwritingTool";
import FortLauderdale from "./pages/FortLauderdale";
import RealEstateAgents from "./pages/locations/RealEstateAgents";
import STRLenders from "./pages/locations/STRLenders";
import SellingYourSTR from "./pages/locations/SellingYourSTR";
import Analyze from "./pages/Analyze";
import Buy from "./pages/Buy";
import Columbus from "./pages/Columbus";
import Poconos from "./pages/Poconos";
import PalmBeach from "./pages/PalmBeach";
import Hollywood from "./pages/Hollywood";
import Atlanta from "./pages/Atlanta";
import SanDiego from "./pages/SanDiego";
import Dallas from "./pages/Dallas";
import MiamiBeach from "./pages/MiamiBeach";
import PalmSprings from "./pages/PalmSprings";
import PhoenixScottsdale from "./pages/PhoenixScottsdale";
import SummitCounty from "./pages/SummitCounty";
import Philadelphia from "./pages/Philadelphia";
import Memphis from "./pages/Memphis";
import AnalyticsRoute from "./pages/Analytics/AnalyticsRoute";
import ListingsPage from "./pages/ForSaleListings/Listings";
import IndividualListingPage from "./pages/ForSaleListings/IndividualListing";
import SellingYourSTRForSocial from "./pages/locations/SellingYourSTRForSocial";
import Comparison from "./pages/Comparison";

interface TrackedRouteProps {
  component: React.ComponentType<any>;
  [key: string]: any;
}

// Create a tracking wrapper component
const TrackedRoute: React.FC<TrackedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const location = useLocation();
  const isFirstRender = useRef(true);
  const mounted = useRef(true);

  useEffect(() => {
    // Cleanup function
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!mounted.current) return;

    // Track every location change
    const trackPageView = () => {
      try {
        ahoy.trackView({
          url: window.location.href,
          path: location.pathname,
          title: document.title,
          referrer: document.referrer,
          search: location.search,
          timestamp: new Date().toISOString()
        });
      } catch (error) {
        console.warn("Failed to track page view:", error);
      }
    };

    // Track initial page load
    if (isFirstRender.current) {
      try {
        if (typeof ahoy.trackVisit === "function" && !ahoy.getVisitId()) {
          ahoy.trackVisit();
        }
      } catch (error) {
        console.warn("Failed to track visit:", error);
      }
      isFirstRender.current = false;
    }

    // Track every navigation
    trackPageView();
  }, [location.pathname, location.search]);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const Routes = () => (
  <Switch>
    <TrackedRoute exact path="/" component={Home} />
    {/* <Route path="/home-2" component={Home2} /> */}
    {/* <Route path={["/contact", "/sign-up", "/login"]} component={Home} /> */}
    <TrackedRoute path="/blog/:slug?" component={Blog} />
    <TrackedRoute path="/best-airbnbs/:slug?" component={BestAirbnbs} />
    <Route
      path="/investor-guides/broward-county-fl-investor-guide-airbnb-rentals"
      exact
      render={() => <Redirect to="/investor-guides/fort-lauderdale-fl" />}
    />
    <Route
      path="/rental-regulations/short-term-rental-regulation-kissimmee-florida"
      exact
      render={() => <Redirect to="/rental-regulations/kissimmee-fl/" />}
    />
    <Route
      path="/str-calculator"
      exact
      render={() => <Redirect to="/analytics/airbnb-calculator" />}
    />
    <TrackedRoute path="/chalet-vs-others" component={Comparison} />
    {/* <Route
      path="/rental-regulations/:slug?"
      render={({ match }) => {
        const { slug } = match.params;
        return <Redirect to={`/analytics/rental-regulations/${slug || ""}`} />;
      }}
    /> */}
    {/* <Route
    //   path="/investor-guides/:slug?"
    //   render={({ match }) => {
    //     const { slug } = match.params;
    //     return <Redirect to={`/analytics/investor-guides/${slug || ""}`} />;
    //   }}
    // /> */}

    <Route
      path="/rental-regulations/palm-springs-ca-rental-regulations"
      exact
      render={() => <Redirect to="/rental-regulations/palm-springs-ca/" />}
    />
    <Route
      path="/rental-regulations/short-term-rental-regulation-pompano-beach-fl"
      exact
      render={() => <Redirect to="/rental-regulations/pompano-beach-fl/" />}
    />
    <Route
      path="/rental-regulations/broward-county-fl-rental-regulations"
      exact
      render={() => <Redirect to="/rental-regulations/fort-lauderdale-fl/" />}
    />
    <Route
      path="/rental-regulations/short-term-rental-regulation-panama-city-beach-fl"
      exact
      render={() => <Redirect to="/rental-regulations/panama-city-beach-fl/" />}
    />
    <Route
      path="/rental-regulations/dallas-tx-rental-regulations"
      exact
      render={() => <Redirect to="/rental-regulations/dallas-tx/" />}
    />
    <Route
      path="rental-regulations/short-term-rental-regulation-san-antonio-tx/"
      exact
      render={() => <Redirect to="/rental-regulations/san-antonio-tx/" />}
    />
    <Route
      path="/rental-regulations/hollywood-fl-str-regulations"
      exact
      render={() => <Redirect to="/rental-regulations/hollywood-fl/" />}
    />
    <Route
      path="/rental-regulations/shelby-county-str-regulation"
      exact
      render={() => <Redirect to="/rental-regulations/memphis-tn/" />}
    />
    <Route
      path="/rental-regulations/philadelphia-pa-str-regulations"
      exact
      render={() => <Redirect to="/rental-regulations/philadelphia-pa/" />}
    />
    <Route
      path="/rental-regulations/short-term-rental-regulation-miami-beach-florida"
      exact
      render={() => <Redirect to="/rental-regulations/miami-beach-fl/" />}
    />
    <TrackedRoute path="/investor-guides/:slug?" component={InvestorGuides} />
    {/* <TrackedRoute path="/investor-guides/:slug?" component={InvestorGuides} /> */}
    <TrackedRoute
      path="/rental-regulations/:slug?"
      component={RentalRegulations}
    />
    <TrackedRoute path="/privacy-policy" component={PrivacyPolicy} />
    <TrackedRoute path="/str-calculator" component={StrCalculator} />
    <TrackedRoute path="/airbnbs-for-sale" component={ListingsPage} />
    <TrackedRoute path="/dscr-calculator" component={DSCRCalculator} />
    {/* <TrackedRoute
      path="/airbnbs-for-sale-listings/:id"
      component={IndividualListingPage}
    /> */}
    <TrackedRoute
      path="/airbnbs-for-sale-listings/:encodedAddress"
      component={IndividualListingPage}
    />
    <TrackedRoute
      path="/1031-exchange/:marketPathParam?"
      component={Service1031Exchange}
    />
    <TrackedRoute path="/turnover-services" component={TurnoverServices} />
    <TrackedRoute path="/str-resources" component={STRResources} />
    <TrackedRoute
      path="/analytics/:type/:market?/:zipcode?"
      component={AnalyticsRoute}
    />
    <TrackedRoute path="/analytics/str-calculator" component={StrCalculator} />
    <TrackedRoute
      path="/remote-manage-your-airbnbs"
      component={ChaletRemoteHost}
    />
    <TrackedRoute
      path="/real-estate-agents/:marketPathParam?"
      component={RealEstateAgents}
    />
    <TrackedRoute
      path="/str-lenders/:marketPathParam?"
      component={STRLenders}
    />
    <TrackedRoute
      path="/sell-your-str/:marketPathParam?"
      component={SellingYourSTR}
    />
    <TrackedRoute
      path="/sell-your-str-social"
      component={SellingYourSTRForSocial}
    />
    <TrackedRoute
      path="/cost-segregation/:marketPathParam?"
      component={CostSegregation}
    />
    <TrackedRoute
      path="/property-management/:marketPathParam?"
      component={PropertyManagement}
    />
    <TrackedRoute
      path="/listing-title-generator"
      component={ListingTitleGenerator}
    />
    <TrackedRoute path="/cost-seg-calculator" component={CostSegCalculator} />
    <TrackedRoute path="/download-whitepaper" component={DownloadWhitepaper} />
    <TrackedRoute path="/download-top-markets" component={DownloadTopMarkets} />
    <TrackedRoute path="/download-report" component={DownloadReportV2} />
    <TrackedRoute
      path="/str-underwriting-tool"
      component={STRUnderwritingTool}
    />
    <TrackedRoute path="/chalet-concierge" component={ChaletConcierge} />
    <TrackedRoute path="/fort-lauderdale-fl" component={FortLauderdale} />
    <TrackedRoute path="/columbus-oh" component={Columbus} />
    <TrackedRoute path="/poconos-pa" component={Poconos} />
    <TrackedRoute path="/hollywood-fl" component={Hollywood} />
    <TrackedRoute path="/palm-beach-fl" component={PalmBeach} />
    <TrackedRoute path="/memphis-tn" component={Memphis} />
    <TrackedRoute path="/philadelphia-pa" component={Philadelphia} />
    <TrackedRoute path="/palm-springs-ca" component={PalmSprings} />
    <TrackedRoute path="/san-diego-ca" component={SanDiego} />
    <TrackedRoute path="/miami-beach-fl" component={MiamiBeach} />
    <TrackedRoute path="/phoenix-scottsdale-az" component={PhoenixScottsdale} />
    <TrackedRoute path="/analyze" component={Analyze} />
    <TrackedRoute path="/buy" component={Buy} />
    <TrackedRoute path="/atlanta-ga" component={Atlanta} />
    <TrackedRoute path="/summit-county-co" component={SummitCounty} />
    <TrackedRoute path="/dallas-tx" component={Dallas} />
    <TrackedRoute component={NotFound} />
  </Switch>
);

export default Routes;
