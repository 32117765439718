import React, { useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import Slider from "react-slick";
import { AppContext } from "../../components/AppContext";
import Text from "../../components/Text";
import Header from "../../components/Header";
import useCookies from "../../hooks/useCookies";
import Flex from "../../components/Flex";
import SectionFooter from "../../components/SectionFooter";
import SplitView from "../../components/SplitView";
import Group from "../../components/Group";
import Section from "../../components/Section";
import { Color } from "../../types";
import "../../styles/RealEstateAgents.scss";
import LiveMarkets from "../../liveMarkets";
import Image from "../../components/Image";
import { VerticalDivider, HorizontalDivider } from "../../components/Divider";
import List from "../../components/List";

const FAQSection = styled(Section)`
  background: var(--white);
  //   padding: 3rem 2rem;
  //   max-width: 95vw;
  box-shadow: none;
  margin: 0 auto;

  h2 {
    color: var(--primary);
    text-align: center;
    margin-bottom: 2rem;
  }

  .faq-item {
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    padding: 1.5rem;
    background-color: var(--lightPurple);
    border: 1px solid var(--primary);

    h3 {
      color: var(--primary);
      margin-bottom: 1rem;
    }

    p {
      color: var(--secondary);
      line-height: 1.6;
    }

    ol {
      padding-left: 1.5rem;
      margin-top: 0.5rem;
    }

    li {
      margin-bottom: 0.5rem;
      font-weight: 300;
    }
  }
`;

const Container = styled(Flex)`
  // flex-direction: column;
  font-size: 2.5rem;
  height: 100vh;
  // width: 50%;
  justify-content: center;
  line-height: 1;
  h1 {
    font-weight: 300;
    font-size: 3.5rem;
    // text-align: center;
    margin-bottom: 0;
    text-align: left;
    // padding-bottom: 1rem;
  }
  h3 {
    font-weight: 300;
    font-size: 1.6rem;
    // text-align: center;
    margin: 0;
  }
  video {
    margin: 2rem;
    max-width: 450px;
    border-radius: 0.5rem;
  }
  padding: 10rem 0;
  .first ${Text} {
    white-space: nowrap;
    font-size: 3.5rem;
  }
  .second h1 {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
    color: var(--primary);
  }
  .third ${Text} {
    font-size: 1.5rem;
  }
  .first {
    padding: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
    // width: 50%;
  }
  .second,
  .third {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-top: 0.75rem;
    padding: 1rem;
    padding-left: 1rem;
    // width: 50%;
  }
  .second {
    padding-top: 0;
  }
  .third {
    // display: block;
    // text-align: left;
    padding: 0rem;
  }
  ${Group} {
    :first-of-type {
      margin-top: 6rem;
    }
  }

  @media (max-width: 810px) {
    // justify-content: start;
    align-items: flex-start;
    margin-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0rem;
    h3 {
      font-size: 2rem;
    }
    .first {
      text-align: center;
      h1 {
        font-size: 2.2rem;
        text-align: left;
        ${Text} {
          font-size: 2.2rem;
          text-align: left;
          line-height: 2.2rem;
        }
      }
    }
    .second {
      // display: none;
      h3 {
        font-size: 1.2rem;
        text-align: left;
        ${Text} {
          font-size: 1.2rem !important;
        }
      }
    }
    video {
      display: none;
    }
    .second {
      padding-bottom: 1rem;
    }
    .third ${Text} {
      padding-bottom: 1rem;
    }
    .second,
    .third {
      text-align: center;
    }
    .third {
      flex-direction: column;
    }
  }
`;

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  // centerMode: true,
  // centerPadding: "60px",
  rows: 1,
  // variableWidth: true,

  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
        // centerMode: true,
        // centerPadding: "60px"
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false
        // centerPadding: "60px"
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
        centerPadding: "60px"
      }
    }
  ]
};

const Stars = () => <div className="stars">★★★★★</div>;

const RealEstateAgents = () => {
  const location = useLocation();
  const { getAllCookies } = useCookies();
  const { marketPathParam } = useParams<any>();
  const searchParams = new URLSearchParams(location.search);

  const { mobileSize } = useContext(AppContext);

  const bodyRef = useRef<HTMLDivElement>();

  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });

  const titleString = `Real Estate Agents With Airbnb Rental Expertise | ${
    LiveMarkets[marketPathParam] || ""
  }`;
  const descriptionString = `Airbnb Realtors. Find the perfect short-term rental investment in the ${
    LiveMarkets[marketPathParam] || ""
  } market with the help of a local experts. Our top Airbnb realtors
   know the market inside and out and can guide you to the best 
   investment with the highest ROI. Connect with Chalet today`;

  const urlSlug = marketPathParam
    ? `https://www.getchalet.com/real-estate-agents/${marketPathParam}`
    : "https://www.getchalet.com/real-estate-agents";

  // Get landing info from localStorage including original UTM params
  const landingInfo = JSON.parse(localStorage.getItem("landingInfo") || "{}");

  const typeformHiddenFields = {
    market_of_interest: LiveMarkets[marketPathParam] || "Not Specified",
    source_page: urlSlug,
    utm_source: landingInfo.utm_source || "",
    utm_medium: landingInfo.utm_medium || "",
    anon_user_id: landingInfo.anon_user_id || "",
    user_id: landingInfo.user_id || "",
    utm_campaign: landingInfo.utm_campaign || "",
    utm_term: landingInfo.utm_term || "",
    utm_content: landingInfo.utm_content || "",
    subquery: marketPathParam || ""
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={urlSlug} />
        <title>{titleString}</title>
        <meta property="og:type" content="website" />
        <meta name="description" content={descriptionString} />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={descriptionString} />
        <meta property="og:image" content="" />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Container ref={bodyRef as any}>
        <div
          style={{
            maxWidth: mobileSize ? "100%" : "80vw",
            paddingRight: mobileSize ? "0" : "0rem"
          }}
        >
          <div id="left-content">
            <h1>
              {marketPathParam &&
                `${LiveMarkets[marketPathParam].split(",")[0]}`}{" "}
              Real Estate Agents With Airbnb Rental Expertise{" "}
            </h1>
            <h2>
              Unlock the best short-term rental opportunities with expert
              guidance tailored to your investment goals
              {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}
            </h2>
            <PopupButton
              id="DU51pTsh"
              hidden={typeformHiddenFields}
              shareGaInstance="true"
              data-id="connect-with-realtor_button"
              className="connect-with-realtor_button"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--quinary)"
                  : "var(--quinary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem"
              }}
            >
              Connect With Airbnb Realtor{" "}
              {marketPathParam &&
                ` in ${LiveMarkets[marketPathParam].split(",")[0]}`}
            </PopupButton>
          </div>
        </div>
        <div style={{ display: "block", maxWidth: "50%" }}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.png`}
            alt="Chalet Partner Real Estate Agents With STR Investing Expertise"
            $mobileSize={mobileSize}
            style={{
              gridRowStart: mobileSize ? "1" : "inherit",
              maxWidth: mobileSize ? "100%" : "400px",
              display: mobileSize ? "none" : "block",
              borderRadius: "0.25rem"
            }}
          />
        </div>
      </Container>
      <Section>
        <Header size={2}>Why Work with a Chalet Partner Agent?</Header>
        <div className="tools-grid">
          <div className="tool-card">
            <Image
              src={`${process.env.PUBLIC_URL}/images/analyze.png`}
              alt="Analyze STR Markets"
            />
            <div className="tool-content">
              <Header
                style={{
                  textAlign: "left",
                  paddingLeft: 0,
                  fontSize: "1.7rem",
                  fontWeight: 600
                }}
                size={3}
              >
                No-Cost Introduction
              </Header>
              <Text
                style={{
                  fontSize: "1.5rem"
                }}
              >
                We’ll connect you with the right agent at no cost or obligation.
                You retain full control of the relationship—we simply make the
                introduction.
              </Text>
            </div>
          </div>

          <div className="tool-card">
            <Image
              src={`${process.env.PUBLIC_URL}/images/buy.png`}
              alt="Browse For-Sale Airbnb Listings"
            />
            <div className="tool-content">
              <Header
                style={{
                  textAlign: "left",
                  paddingLeft: 0,
                  fontSize: "1.7rem",
                  fontWeight: 600
                }}
                size={3}
              >
                Short-Term Rental Insights
              </Header>
              <Text style={{ fontSize: "1.5rem" }}>
                Get expert guidance on local regulations, neighborhood trends,
                revenue potential, and other critical factors to maximize your
                Airbnb investment.
              </Text>
            </div>
          </div>
          <div className="tool-card">
            <Image
              src={`${process.env.PUBLIC_URL}/images/manage.png`}
              alt="Estimate STR Revenue"
            />
            <div className="tool-content">
              <Header
                style={{
                  textAlign: "left",
                  paddingLeft: 0,
                  fontSize: "1.7rem",
                  fontWeight: 600
                }}
                size={3}
              >
                Investor-Focused
              </Header>
              <Text style={{ fontSize: "1.5rem" }}>
                Work with agents who specialize in investor needs, including
                1031 exchanges, LLC purchases, and strategies for building a
                profitable portfolio{" "}
                {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}.
              </Text>
            </div>
          </div>
        </div>
        <PopupButton
          id="DU51pTsh"
          shareGaInstance="true"
          hidden={{
            ...typeformHiddenFields,
            source_page: "/real-estate-agents",
            start_point: "buying"
          }}
          data-id="connect-with-realtor_button"
          className="connect-with-realtor_button btn-consult"
          style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
        >
          Connect With Airbnb Realtor{" "}
          {marketPathParam &&
            ` in ${LiveMarkets[marketPathParam].split(",")[0]}`}
        </PopupButton>
      </Section>
      <Group
        style={{
          // background:
          //   "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #DB005F 36.46%, #2F354B 67.71%, #FB929D 71.35%)",
          // background: "var(--radialGradient)",
          background: "var(--lightPurple)",
          maxWidth: "100%",
          padding: "0"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            background: "none",
            maxWidth: "100%"
          }}
        >
          <Header size={2} color={Color.sixth}>
            We’ve Done the Research so You Don’t Have To
          </Header>
          <SplitView
            split="3:2"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "90vw" : "85vw",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"
                style={{
                  lineHeight: "2.5rem",
                  fontSize: "1.3rem",
                  fontWeight: 300
                }}
                color={Color.sixth}
              >
                Why settle for a generalist? You’re not just buying a
                home—you’re starting a business. Chalet specializes exclusively
                in the short-term rental industry, giving us the unique
                expertise to identify realtors who truly understand its
                complexities. We personally vet every agent to ensure you’re
                connected with professionals who excel in Airbnb investing{" "}
                {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}.
              </Text>
              {/* {marketPathParam && (
                <a
                  href={`/analytics/rental-regulations/${marketPathParam}/`}
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "var(--quinary)",
                    fontSize: "1.5rem"
                  }}
                  rel="noreferrer"
                >
                  Airbnb Regulation in {LiveMarkets[marketPathParam]}
                </a>
              )} */}
              <PopupButton
                id="DU51pTsh"
                hidden={typeformHiddenFields}
                shareGaInstance="true"
                data-id="connect-with-realtor_button"
                className="connect-with-realtor_button"
                style={{
                  // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                  backgroundColor: mobileSize
                    ? "var(--quinary)"
                    : "var(--quinary)",
                  textTransform: "uppercase",
                  color: "var(--white)",
                  padding: "1rem",
                  border: 0,
                  fontWeight: 400,
                  width: mobileSize ? "100%" : "initial",
                  textDecoration: "none",
                  display: mobileSize ? "block" : "block",
                  fontSize: "1rem",
                  cursor: "pointer",
                  textAlign: "center",
                  boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                  borderRadius: "0.25rem",
                  // display: mobileSize ? "block" : "initial",
                  margin: mobileSize ? "1rem auto" : "1rem auto"
                }}
              >
                Connect With Airbnb Realtor{" "}
                {marketPathParam &&
                  ` in ${LiveMarkets[marketPathParam].split(",")[0]}`}
              </PopupButton>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/1031-exchange.png`}
              alt="Local STR Experts With a Proven Track Record"
              $mobileSize={mobileSize}
              style={{
                display: "flex",
                gridRowStart: mobileSize ? "1" : "inherit",
                justifyContent: "right",
                margin: "0 auto",
                // maxWidth: mobileSize ? "100vw" : "300px"
                maxWidth: mobileSize ? "100%" : "400px",
                border: "5px solid var(--quinary)",
                padding: mobileSize ? "1rem !important" : "1rem"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Section
        style={{
          boxShadow: "none",
          background: "var(--white)",
          maxWidth: "100%",
          padding: mobileSize ? "2rem" : "4rem 0"
        }}
      >
        <Header size={2} style={{ textAlign: "center", marginBottom: "1rem" }}>
          What Sets Our Agents Apart{" "}
          {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}
        </Header>
        <Header
          size={3}
          style={{
            maxWidth: "1200px",
            margin: "0rem auto 2rem",
            color: "var(--sixth)",
            fontSize: mobileSize ? "1.2rem" : "1.5rem"
          }}
        >
          Our partner agents take a uniquely tailored approach, going above and
          beyond to provide a customized service that meets your specific needs
          as an STR investor. Here’s how they stand out:
        </Header>

        <div className="how-it-works-container">
          {/* <div className="timeline">
            {[1, 2, 3, 4].map((step) => {
              const { ref, inView } = useInView(observerOptions);

              useEffect(() => {
                handleStepVisibility(inView, step);
              }, [inView, step]);

              return (
                <div
                  key={step}
                  ref={ref}
                  className={`step ${activeStep === step ? "active" : ""}`}
                >
                  <div className="step-number">{step}</div>
                  {step !== 4 && <div className="connector" />}
                </div>
              );
            })}
          </div> */}

          <div className="steps-content">
            <div className="step-item">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/analyze.png`}
                  alt="Analyze"
                />
              </div>
              <div className="step-text">
                <h3>Market Projections</h3>
                <p>
                  Gain access to detailed cash flow estimates and ROI analyses
                  to make confident, data-driven decisions{" "}
                  {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}.
                </p>
                {/* <a href="/analyze" className="step-link">
                  Start Analyzing
                </a> */}
              </div>
            </div>

            <div className="step-item">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/buy.png`}
                  alt="Buy"
                />
              </div>
              <div className="step-text">
                <h3>Demand Trends</h3>
                <p>
                  Identify high-yield properties using advanced market analytics
                  and local expertise.
                </p>
                <a href="/buyers-agent" className="step-link">
                  Find an Airbnb Buyer&apos;s Agent
                </a>
              </div>
            </div>
            {/* 
            <div className="step-item">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/manage.png`}
                  alt="Manage"
                />
              </div>
              <div className="step-text">
                <h3>Manage</h3>
                <p>
                  Whether you&apos;re hiring a property manager or
                  self-managing, Chalet offers free referrals to top
                  technologies, vendors, and tools to streamline operations{" "}
                  {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}.
                </p>
              </div>
            </div> */}

            {/* <div className="step-item">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/sell.png`}
                  alt="Sell"
                />
              </div>
              <div className="step-text">
                <h3>Revenue Strategies</h3>
                <p>
                  Receive expert advice on pricing, property management, and
                  strategies to optimize your rental returns.
                </p>
              </div>
            </div> */}
            <div className="step-item">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/chalet-home.png`}
                  alt="Real Estate Investment Strategies for Airbnb Rentals"
                />
              </div>
              <div className="step-text">
                <h3>Real Estate Investment Strategies</h3>
                <p>
                  Navigate complex topics like 1031 exchanges, LLC versus
                  personal ownership structures, and more.
                </p>
                {marketPathParam && (
                  <a
                    href={`/analytics/investor-guides/${marketPathParam}/`}
                    target="_blank"
                    className="step-link"
                    style={{
                      cursor: "pointer"
                      // textDecoration: "underline",
                      // color: "var(--quinary)",
                      // fontSize: "1.5rem"
                    }}
                    rel="noreferrer"
                  >
                    {" "}
                    {LiveMarkets[marketPathParam]} Investor Guide
                  </a>
                )}
              </div>
            </div>
            <div className="step-item">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/buy-str.png`}
                  alt="Sell"
                />
              </div>
              <div className="step-text">
                <h3>Investment Experience</h3>
                <p>
                  Whether it’s your first STR purchase, a switch from long-term
                  rentals, or an expansion as a seasoned Airbnb investor, our
                  agents meet you where you are with expert guidance.
                </p>
                {/* <a href="/list" className="step-link">
                  List with Chalet
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <PopupButton
          id="DU51pTsh"
          shareGaInstance="true"
          hidden={{
            ...typeformHiddenFields,
            source_page: "/real-estate-agents",
            start_point: "buying"
          }}
          data-id="connect-with-realtor_button"
          className="connect-with-realtor_button btn-consult"
          style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
        >
          Connect With Airbnb Realtor
        </PopupButton>
      </Section>
      <Section
        id="home-statistics"
        style={{
          // backgroundImage: `url('${process.env.PUBLIC_URL}/images/radial-gradient.webp')`,
          background: "var(--sixth)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <div className="stats">
          <span id="alt-red">Connected Investors With</span>
          <span id="highlight">100M+</span>
          <span id="alt-black">in Short-Term Rental Assets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-black">Short-Term Rental Partners</span>
          <span id="highlight">All 50 states</span>
          <span id="alt-black">200+ Markets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-red">Analyzing</span>
          <span id="highlight">1M+</span>
          <span id="alt-red">Short-Term Rentals</span>
        </div>
      </Section>
      {/* <Group id="section1">
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit",
            maxWidth: "100%"
          }}
        >
          <Header size={2}>
            Find Your Perfect Short-Term Rental Investment With Chalet
          </Header>

          <SplitView
            split="2:3"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "right",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/invest-with-experts.png`}
                alt="Buy your Airbnb rental with Chalet"
                style={{
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "400px",
                  padding: mobileSize ? "0.5rem" : "0rem",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"
                style={{ fontSize: "1.3rem", fontWeight: 300 }}
                // style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                Are you looking for a reliable and experienced real estate agent
                to help you find the perfect short-term rental investment{" "}
                {marketPathParam && "in"} {LiveMarkets[marketPathParam]}?
              </Text>
              <Text
                block
                align="left"
                style={{ fontSize: "1.3rem", fontWeight: 300 }}
              >
                Chalet partner real estate agents know which neighborhoods
                perform the best from a short-term rental perspective, and have
                a lot of experience working with real estate investors.
              </Text>
              {marketPathParam && (
                <a
                  href={`/analytics/investor-guides/${marketPathParam}/`}
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "var(--quinary)",
                    fontSize: "1.5rem"
                  }}
                  rel="noreferrer"
                >
                  Read our Investor Guide To Airbnb Rentals In{" "}
                  {LiveMarkets[marketPathParam]}
                </a>
                // <Text
                //   align="left"
                //   style={{
                //     cursor: "pointer",
                //     textDecoration: "underline",
                //     color: "var(--quinary)"
                //   }}
                //   onClick={handleInvestorGuideClick}
                // >
                //   Read our Investor Guide To Airbnb Rentals In{" "}
                //   {LiveMarkets[marketPathParam]}
                // </Text>
              )}
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group> */}
      {/* <Group
        style={{
          paddingBottom: "0rem",
          maxWidth: "100%",
          paddingTop: "0rem",
          // backgroundImage: `url("${process.env.PUBLIC_URL}/images/blue-fade.webp")`,
          background: "var(--white)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            maxWidth: "100%",
            background: "none"
          }}
        >
          <Header size={2}>
            Validated Rental Numbers for Your Peace of Mind
          </Header>
          <SplitView
            split="1:2"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <Image
              src={`${process.env.PUBLIC_URL}/images/graph.png`}
              alt="Top locations for Airbnb Investment"
              $mobileSize={mobileSize}
              style={{
                padding: "0rem",
                // pos/ition: "absolute",
                // left: 0,
                // bottom: mobileSize ? "-8rem" : "-3rem",
                maxWidth: mobileSize ? "400px" : "100%",
                // display: "block",
                borderRadius: "0.25rem"
              }}
            />
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text block align="left" style={{ fontSize: "1.3rem" }}>
                Our partners validate the rental numbers so that you can make
                investment decisions with confidence.
              </Text>
              <Text block align="left" style={{ fontSize: "1.3rem" }}>
                They will help you understand the potential return on investment
                and make sure that your investment is based on accurate and
                trustworthy information.
              </Text>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group> */}
      <Section
        style={{
          boxShadow: "none",
          // background: `url("${process.env.PUBLIC_URL}/images/blue-fade.webp")`,
          // background: `url("${process.env.PUBLIC_URL}/images/about2.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          background: "var(--lightPurple)",
          maxWidth: "100%",
          // padding: "2rem 3rem",
          position: "relative"
        }}
      >
        <Header size={2} style={{ margin: "2rem auto 0" }}>
          Testimonials
        </Header>
        <div
          style={{
            width: "100%",
            margin: "0 auto",
            display: "block"
          }}
        >
          {/* <Slider {...sliderSettings}>
          i
          </Slider> */}
          <div className="testimonials-container">
            <div className="testimonial">
              <Image
                src="https://a0.muscache.com/im/pictures/prohost-api/Hosting-1067591824691586398/original/bce45fe4-ec7e-4cd2-b3a5-320904fea506.jpeg?im_w=1200"
                alt="Justin B."
                style={{
                  width: "140px",
                  height: "140px",
                  borderRadius: "50%",
                  objectFit: "cover"
                }}
                $mobileSize={mobileSize}
              />
              <Header
                size={3}
                style={{ paddingBottom: "0.5rem", textAlign: "center" }}
              >
                Justin B.
              </Header>
              <Text>
                My wife and I found Chalet when we were in search of a short
                term rental property, but needed some professional help. Chalet
                team replied back quickly and referred me to a local agent in
                the market where we were searching.
              </Text>
              <Stars />
              <a
                href="https://www.airbnb.com/rooms/1067591824691586398"
                target="_blank"
                style={{
                  display: "block",
                  margin: "1rem auto",
                  color: "var(--white)",
                  borderRadius: "0.5rem",
                  textDecoration: "none",
                  textAlign: "center",
                  padding: "0.5rem",
                  background: "var(--linearGradient)"
                }}
                rel="noreferrer"
              >
                Book a stay at Justin&apos;s home
              </a>
            </div>
            <div className="testimonial">
              <Image
                src="https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MTAyNDA5NjMzMjEwNTM3NzgyNA%3D%3D/original/aad2a319-59e6-40c1-91fb-1b831e327143.jpeg?im_w=1440"
                alt="Jimmy O."
                style={{
                  width: "140px",
                  height: "140px",
                  borderRadius: "50%",
                  objectFit: "cover"
                }}
                $mobileSize={mobileSize}
              />
              <Header
                size={3}
                style={{ paddingBottom: "0.5rem", textAlign: "center" }}
              >
                Jimmy O.
              </Header>
              <Text>
                Chalet hooked me up with that amazing realtor in Tampa with
                great STR knowledge. Plus, they set me up with some folks for a
                Cost Segregation study that saved me a ton on taxes.
              </Text>
              <Stars />
              <a
                href="https://www.airbnb.com/rooms/1024096332105377824"
                target="_blank"
                style={{
                  display: "block",
                  margin: "1rem auto",
                  color: "var(--white)",
                  borderRadius: "0.5rem",
                  textDecoration: "none",
                  textAlign: "center",
                  padding: "0.5rem",
                  background: "var(--linearGradient)"
                }}
                rel="noreferrer"
              >
                Book a stay at Jimmy&apos;s home
              </a>
            </div>
            <div className="testimonial">
              <Image
                src="https://a0.muscache.com/im/pictures/miso/Hosting-564716174289419914/original/9ecc5107-abe9-4729-bee6-d3c1f298fb64.jpeg?im_w=1440"
                alt="Dorinda A."
                style={{
                  width: "140px",
                  height: "140px",
                  borderRadius: "50%",
                  objectFit: "cover"
                }}
                $mobileSize={mobileSize}
              />
              <Header
                size={3}
                style={{ paddingBottom: "0.5rem", textAlign: "center" }}
              >
                Dorinda A.
              </Header>
              <Text>
                My husband and I had a dream of moving to a an active, outdoors
                community for our retirement. But, instead of just buying an
                expensive home, we needed a property that could also provide
                flexible income. That is where Chalet stepped in. Their
                comprehensive data research was compelling. But, the concierge,
                personal approach was out of this world.
              </Text>
              <Stars />
              <a
                href="https://www.airbnb.com/rooms/1067591824691586398"
                target="_blank"
                style={{
                  display: "block",
                  margin: "1rem auto",
                  color: "var(--white)",
                  borderRadius: "0.5rem",
                  textDecoration: "none",
                  textAlign: "center",
                  padding: "0.5rem",
                  background: "var(--linearGradient)"
                }}
                rel="noreferrer"
              >
                Book a stay at Dorinda&apos;s home
              </a>
            </div>
          </div>
        </div>
      </Section>
      <Section
        style={{
          boxShadow: "none",
          background: "var(--sixth)",
          maxWidth: "100%",
          borderRadius: "0",
          padding: "4rem 0"
        }}
      >
        <div className="get-started-container">
          <div className="get-started-content">
            <h2>Ready to Connect?</h2>
            <p>
              It’s free to connect with our expert STR agents
              {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}.
              There’s no obligation—just smarter investing.
            </p>
            <div className="get-started-buttons">
              {/* <Button onClick={() => history.push("/buying-an-airbnb")}>
                I am buying an Airbnb
              </Button> */}
              <PopupButton
                id="DU51pTsh"
                shareGaInstance="true"
                hidden={{
                  ...typeformHiddenFields,
                  source_page: "/(cta-bottom-homepage)",
                  start_point: "buying"
                }}
                data-id="connect-with-realtor_button"
                className="connect-with-realtor_button_top btn-consult"
                style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
              >
                Connect with Airbnb Agent{" "}
                {marketPathParam &&
                  ` in ${LiveMarkets[marketPathParam].split(",")[0]}`}
              </PopupButton>
            </div>
          </div>
          <div className="get-started-image">
            <Image
              src={`${process.env.PUBLIC_URL}/images/house-with-palm-trees.svg`}
              alt="House with palm trees illustration"
            />
          </div>
        </div>
      </Section>
      {/* <Group style={{ background: "var(--primary)" }}>
        <Section
          style={{
            maxWidth: "100%",
            background: "none",
            boxShadow: "none",
            padding: mobileSize ? "2rem" : "3rem 2rem"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.white}
          >
            Connect With the Right Vendors for Success
          </Header>
          <SplitView
            split="2:1"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
              // margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text
                block
                align="left"
                color={Color.white}
                style={{ fontSize: "1.3rem" }}
              >
                Our real estate agents have a well-established network of local
                vendors in the {LiveMarkets[marketPathParam]} market who can
                help you manage and maintain your short-term rental property
                effectively.
              </Text>
              <Text
                block
                align="left"
                color={Color.white}
                style={{ fontSize: "1.3rem" }}
              >
                From property management to guest services, they can connect you
                with the right vendors to ensure your property is successful.
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: mobileSize ? "center" : "left",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/chalet-partner-vendors.webp`}
                alt="Airbnb Rental Investor Guides. Free Market Reports. Median Revenue, ADR, Occupacny rates"
                style={{
                  padding: "0rem",
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "300px",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group> */}
      {/* <Group style={{ background: "var(--white)" }}>
        <Section
          style={{
            maxWidth: "100%",
            background: "none",
            boxShadow: "none",
            padding: mobileSize ? "2rem" : "3rem 2rem"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.primary}
          >
            Partner With Experienced Short-Term Rental Investors
          </Header>
          <SplitView
            split="1:2"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
              // margin: "0 auto"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: 0,
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.png`}
                alt="Airbnb Rental Investor Guides. Free Market Reports. Median Revenue, ADR, Occupacny rates"
                style={{
                  // padding: "0rem",
                  borderRadius: "0.25rem",
                  // maxWidth: mobileSize ? "100%" : "300px"
                  maxWidth: mobileSize ? "100%" : "400px",
                  padding: mobileSize ? "1rem" : "0rem"
                  // width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text
                block
                align="left"
                color={Color.primary}
                style={{ fontSize: "1.3rem" }}
              >
                At Chalet, we bring you the expertise of real estate agents who
                have invested in the short-term rental market themselves.
              </Text>
              <Text
                block
                align="left"
                color={Color.primary}
                style={{ fontSize: "1.3rem" }}
              >
                They understand the ins and outs of the business and can provide
                valuable insights and advice based on their own experiences.
              </Text>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group> */}
      <FAQSection>
        <Header size={2}>Frequently Asked Questions</Header>
        <div className="faq-item">
          <Header size={2}>
            🤝 What makes a Chalet partner agent different from a traditional
            real estate agent{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}?
          </Header>
          <Text block>
            Chalet partner agents{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}{" "}
            specialize in short-term rental (STR) investments—a niche within
            real estate that most agents aren’t equipped to navigate. They
            understand the nuances of STR-specific strategies, including market
            analytics, revenue optimization, and compliance with local
            regulations. Unlike traditional agents, who often focus on primary
            residences, our agents are trained to help you maximize your
            investment potential in the STR market.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={2}>
            🔄 How is your service different from other agent-matching services?
          </Header>
          <Text block>
            Unlike platforms that operate on an advertising model, Chalet’s
            agent-matching service is based on referrals. This means we only
            earn revenue if the investor successfully works with one of our
            recommended agents to buy or sell a property. This referral-based
            model ensures we’re incentivized to connect you with only the best
            agents in the industry—those who have a proven track record in
            short-term rental (STR) investments. We don’t accept just anyone
            into our network.
          </Text>
          <Text block>
            Each agent is personally vetted for their expertise, experience, and
            ability to deliver results in the STR space. The bottom line: our
            success depends entirely on your success, so we prioritize quality,
            trust, and performance over quantity.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={2}>
            How does Chalet make money if the introduction is free and there’s
            no obligation?
          </Header>
          <Text block>
            Chalet operates as a licensed real estate brokerage, Chalet Realty
            LLC, based in Texas. This allows us to refer investors to agents
            across the U.S. and earn a referral commission. The way it works is
            simple: our partner agents agree to share a small percentage of the
            commission they earn from helping you buy or sell a property.
          </Text>
          <Text block>
            This means there’s no direct cost to you for using our referral
            service—our fee comes from the agent’s commission, which they would
            earn anyway for representing the transaction. It’s a win-win: you
            get connected to an expert at no cost, and we’re compensated for
            facilitating the introduction.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={2}>
            📣 Is there any cost or obligation to work with a Chalet-recommended
            agent?
          </Header>
          <Text block>
            No. Our service is completely free, and there’s no obligation. We
            simply connect you with the right agent, and you own the
            relationship from there.
          </Text>
          {/* <List
            items={[
              <Text>
                We share your listing with our email subscribers, one of the
                largest proprietary lists of Airbnb rental investors in the
                country.
              </Text>,
              <Text>
                Your property will be featured on our dedicated Airbnb
                properties for sale section on our website.
              </Text>,
              <Text>
                We promote featured listings through targeted social media
                campaigns and collaborate with our network of experienced real
                estate agents and their partners to find interested buyers.
              </Text>
            ]} */}
          {/* /> */}
        </div>
        <div className="faq-item">
          <Header size={2}>How do you vet the agents in your network?</Header>
          <Text block>
            At Chalet, we take agent vetting seriously to ensure you’re
            connected with top-tier professionals who specialize in short-term
            rental (STR) investments. Every agent in our network undergoes a
            thorough evaluation process, including:
          </Text>
          <List
            items={[
              <Text>
                <Text style={{ fontWeight: 400 }}>Personal Interviews:</Text> We
                assess their experience in STR investing, deep knowledge of
                local markets, and their ability to help investors achieve their
                goals.
              </Text>,
              <Text>
                <Text style={{ fontWeight: 400 }}>
                  Track Record Verification:
                </Text>{" "}
                We review their past performance to ensure they’ve successfully
                guided investors in buying, managing, or selling STR properties.
              </Text>
            ]}
          />
          <Text block>
            Our commitment doesn’t stop after the initial vetting. We also:
          </Text>
          <List
            items={[
              <Text>
                <Text style={{ fontWeight: 400 }}>
                  Collect Investor Feedback:
                </Text>{" "}
                After every referral, we ask our investors for detailed feedback
                on their experience to ensure our agents consistently meet
                expectations.
              </Text>,
              <Text>
                <Text style={{ fontWeight: 400 }}>
                  Monitor Agent Performance:
                </Text>{" "}
                We track ongoing results, including their ability to close deals
                and their expertise in navigating the ever-changing STR
                landscape.
              </Text>
            ]}
          />
          <Text block>
            This continuous improvement process ensures that our network stays
            exclusive to the best agents who truly understand the complexities
            of STR investments and deliver exceptional value to investors.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            Can Chalet also help me with a lending referral?{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}?
          </Header>
          <Text block>
            Absolutely! We can connect you with our trusted lending partners who
            specialize in short-term rental (STR) financing. Just like our
            partner agents, our lending partners understand the unique needs of
            STR investors and offer a variety of loan products, including:
          </Text>
          <List
            items={[
              <Text>
                <Text style={{ fontWeight: 400 }}>Conventional Loans:</Text>{" "}
                Ideal for traditional financing needs.
              </Text>,
              <Text>
                <Text style={{ fontWeight: 400 }}>
                  Short-Term Rental-Specific Loans:
                </Text>{" "}
                Options like DSCR (Debt Service Coverage Ratio) loans designed
                to cater to STR properties by considering rental income
                potential.
              </Text>
            ]}
          />
          <Text block>
            Best of all, there’s 0 cost and no commitment to this connection—our
            goal is to provide you with the resources you need to succeed.
          </Text>
          <PopupButton
            id="DU51pTsh"
            hidden={typeformHiddenFields}
            shareGaInstance="true"
            data-id="connect-with-realtor_button"
            className="connect-with-realtor_button"
            style={{
              // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
              backgroundColor: mobileSize ? "var(--quinary)" : "var(--quinary)",
              textTransform: "uppercase",
              color: "var(--white)",
              padding: "1rem",
              border: 0,
              fontWeight: 400,
              width: mobileSize ? "100%" : "initial",
              textDecoration: "none",
              display: mobileSize ? "block" : "block",
              fontSize: "1rem",
              cursor: "pointer",
              textAlign: "center",
              boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
              borderRadius: "0.25rem",
              // display: mobileSize ? "block" : "initial",
              margin: mobileSize ? "1rem auto" : "1rem auto"
            }}
          >
            Connect With Airbnb Realtor
          </PopupButton>
        </div>
        <div className="faq-item">
          <Header size={3}>Which markets do your partner agents cover?</Header>
          <Text block>
            Our agents cover top-performing STR markets across the U.S., from
            urban hotspots to high-demand vacation destinations.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            Can I still use Chalet’s service if I already have a property in
            mind?
          </Header>
          <Text block>
            Yes! If you’ve identified a property, our agents can assist with due
            diligence, pricing analysis, and ensuring it’s a smart STR
            investment.
          </Text>
        </div>
      </FAQSection>
    </>
  );
};

export default RealEstateAgents;
