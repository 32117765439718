import React, { useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import Slider from "react-slick";
import ahoy from "ahoy.js";
import { AppContext } from "../../components/AppContext";
import Text from "../../components/Text";
import Header from "../../components/Header";
import useCookies from "../../hooks/useCookies";
import Flex from "../../components/Flex";
import List from "../../components/List";
import SectionFooter from "../../components/SectionFooter";
import SplitView from "../../components/SplitView";
import Group from "../../components/Group";
import Section from "../../components/Section";
import { Color } from "../../types";
// import "../../styles/STRLenders.scss";
import "../../styles/CostSegregation.scss";
import LiveMarkets from "../../liveMarkets";
import Image from "../../components/Image";
import { VerticalDivider, HorizontalDivider } from "../../components/Divider";
import HR from "../../components/HR";
import Button from "../../components/Button";

const Container = styled(Flex)`
  // flex-direction: column;
  font-size: 2.5rem;
  height: 100vh;
  width: 80vw;
  margin: 0 auto;
  justify-content: center;
  line-height: 1;
  h1 {
    font-weight: 300;
    font-size: 3.5rem;
    // text-align: center;
    margin-bottom: 0;
    text-align: left;
    // padding-bottom: 1rem;
  }
  h3 {
    font-weight: 300;
    font-size: 1.6rem;
    // text-align: center;
    margin: 0;
  }
  video {
    margin: 2rem;
    max-width: 450px;
    border-radius: 0.5rem;
  }
  padding: 10rem 0;
  .first ${Text} {
    white-space: nowrap;
    font-size: 3.5rem;
  }
  .second h1 {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
    color: var(--primary);
  }
  .third ${Text} {
    font-size: 1.5rem;
  }
  .first {
    padding: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
    // width: 50%;
  }
  .second,
  .third {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-top: 0.75rem;
    padding: 1rem;
    padding-left: 1rem;
    // width: 50%;
  }
  .second {
    padding-top: 0;
  }
  .third {
    // display: block;
    // text-align: left;
    padding: 0rem;
  }
  ${Group} {
    :first-of-type {
      margin-top: 6rem;
    }
  }

  @media (max-width: 810px) {
    // justify-content: start;
    align-items: flex-start;
    margin-top: 4rem;
    padding-left: 1rem;
    width: 100vw;
    padding-right: 1rem;
    padding-bottom: 0rem;
    h3 {
      font-size: 2rem;
    }
    .first {
      text-align: center;
      h1 {
        font-size: 2.2rem;
        text-align: left;
        ${Text} {
          font-size: 2.2rem;
          text-align: left;
          line-height: 2.2rem;
        }
      }
    }
    .second {
      // display: none;
      h3 {
        font-size: 1.2rem;
        text-align: left;
        ${Text} {
          font-size: 1.2rem !important;
        }
      }
    }
    video {
      display: none;
    }
    .second {
      padding-bottom: 1rem;
    }
    .third ${Text} {
      padding-bottom: 1rem;
    }
    .second,
    .third {
      text-align: center;
    }
    .third {
      flex-direction: column;
    }
  }
`;

const FAQSection = styled(Section)`
  background: var(--white);
  //   padding: 3rem 2rem;
  //   max-width: 95vw;
  box-shadow: none;
  margin: 0 auto;

  h2 {
    color: var(--primary);
    text-align: center;
    margin-bottom: 2rem;
  }

  .faq-item {
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    padding: 1.5rem;
    background-color: var(--lightPurple);
    border: 1px solid var(--primary);

    h3 {
      color: var(--primary);
      margin-bottom: 1rem;
    }

    p {
      color: var(--secondary);
      line-height: 1.6;
    }

    ol {
      padding-left: 1.5rem;
      margin-top: 0.5rem;
    }

    li {
      margin-bottom: 0.5rem;
      font-weight: 300;
    }
  }
`;
const DisclaimerSection = styled.div`
  background: var(--lightPurple);
  padding: 3rem 2rem;
  margin-top: 2rem;
  border-top: 1px solid var(--sixth);

  .disclaimer-container {
    max-width: 1200px;
    margin: 0 auto;

    h3 {
      color: var(--primary);
      font-size: 1.3rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .disclaimer-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }

    .disclaimer-item {
      background: var(--white);
      padding: 1.5rem;
      border-radius: 0.5rem;
      border: 1px solid var(--sixth);

      h4 {
        color: var(--sixth);
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        font-weight: 600;
      }

      p {
        color: var(--primary);
        font-size: 0.9rem;
        line-height: 1.5;
      }
    }
  }
`;

const STRLenders = () => {
  const history = useHistory();
  const location = useLocation();
  const { getAllCookies } = useCookies();
  const { marketPathParam } = useParams<any>();
  const searchParams = new URLSearchParams(location.search);

  const { mobileSize } = useContext(AppContext);

  const bodyRef = useRef<HTMLDivElement>();

  const cookies: Record<string, string> = {};
  const handleNavigation = (path: string, navSection: string) => {
    // Track the navigation click event
    ahoy.track("navigation_click", {
      section: navSection,
      from: location.pathname,
      to: path,
      timestamp: new Date().toISOString()
    });

    // Navigate to the new path
    history.push(path);
  };

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });

  const titleString = `Cost Segregation for Airbnb: Maximize Short-Term Rental Tax Savings ${
    LiveMarkets[marketPathParam] ? ` | ${LiveMarkets[marketPathParam]}` : ""
  }`;
  const descriptionString = `Save tens of thousands in taxes with cost segregation for Airbnb ${
    LiveMarkets[marketPathParam] ? `in ${LiveMarkets[marketPathParam]}` : ""
  }. Expert guide for self-managing short-term rental investors to maximize tax benefits.`;

  const urlSlug = marketPathParam
    ? `https://www.getchalet.com/str-lenders/${marketPathParam}`
    : "https://www.getchalet.com/str-lenders";

  // Get landing info from localStorage including original UTM params
  const landingInfo = JSON.parse(localStorage.getItem("landingInfo") || "{}");

  const typeformHiddenFields = {
    market_of_interest: LiveMarkets[marketPathParam] || "Not Specified",
    source_page: urlSlug,
    utm_source: landingInfo.utm_source || "",
    utm_medium: landingInfo.utm_medium || "",
    anon_user_id: landingInfo.anon_user_id || "",
    user_id: landingInfo.user_id || "",
    utm_campaign: landingInfo.utm_campaign || "",
    utm_term: landingInfo.utm_term || "",
    utm_content: landingInfo.utm_content || "",
    subquery: marketPathParam || ""
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={urlSlug} />
        <title>{titleString}</title>
        <meta property="og:type" content="website" />
        <meta name="description" content={descriptionString} />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={descriptionString} />
        <meta property="og:image" content="" />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Container ref={bodyRef as any}>
        <div
          style={{
            maxWidth: mobileSize ? "100%" : "80vw",
            paddingRight: mobileSize ? "0" : "0rem"
          }}
        >
          <div id="left-content">
            <h1>
              Short-Term Rental Cost Segregation Guide: How to Maximize Tax
              Savings{" "}
            </h1>
            <h2>
              Turn Depreciation into Airbnb Investment Tax Deductions
              {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}
            </h2>
            <PopupButton
              id="Q3vyi5Mv"
              hidden={typeformHiddenFields}
              shareGaInstance="true"
              data-id="connect-with-cost-seg-specialist_button"
              className="connect-with-cost-seg-specialist_button"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--quinary)"
                  : "var(--quinary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem"
              }}
            >
              Connect With a Cost Segregation Expert
            </PopupButton>
          </div>
        </div>
        <div style={{ display: "block", maxWidth: "50%" }}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/cost-segregation.webp`}
            alt="Cost Segregation Experts for Short-Term Rental Investors"
            $mobileSize={mobileSize}
            style={{
              gridRowStart: mobileSize ? "1" : "inherit",
              maxWidth: mobileSize ? "100%" : "400px",
              display: mobileSize ? "none" : "block",
              borderRadius: "0.25rem"
            }}
          />
        </div>
      </Container>
      <Section
        style={{
          boxShadow: "none",
          maxWidth: "100vw",
          background: "var(--white)"
        }}
      >
        <Header
          size={2}
          style={{
            textAlign: "center",
            marginBottom: "0rem",
            paddingBottom: "0rem",
            fontSize: mobileSize ? "1.7rem" : "2.2rem"
          }}
        >
          Key Takeaways
        </Header>
        <Header
          size={4}
          style={{
            // color: "var(--sixth)",
            fontWeight: 300,
            // marginBottom: "3rem",
            maxWidth: mobileSize ? "100%" : "85vw",
            margin: mobileSize ? "0 auto" : "0rem auto 3rem"
            // paddingTop: "0rem",
            // marginTop: "0rem"
          }}
        >
          A cost segregation study is a powerful tax strategy with major
          benefits for short-term rental (STR) owners{" "}
          {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}. By
          accelerating depreciation on specific assets, it offers substantial
          financial advantages, including increased cash flow and reduced
          taxable income. For those who self-manage their properties, it’s a
          game-changing opportunity to maximize tax benefits while maintaining
          operational control.
        </Header>
        <div className="tools-grid">
          <div className="tool-card">
            <Image
              src={`${process.env.PUBLIC_URL}/images/manage.png`}
              alt="Estimate STR Revenue"
            />
            <div className="tool-content">
              <Header
                style={{
                  textAlign: "left",
                  paddingLeft: 0,
                  fontSize: "1.5rem",
                  fontWeight: 600
                }}
                size={3}
              >
                Accelerated Depreciation
              </Header>
              <Text
                style={{
                  fontSize: "1.2rem"
                }}
              >
                Cost segregation enables STR owners to reclassify components
                like furniture, appliances, and landscaping into shorter
                depreciation schedules (e.g., 5, 7, or 15 years instead of 27.5
                or 39 years). This results in:
              </Text>
              <ul>
                <li style={{ fontWeight: 300, fontSize: "1.2rem" }}>
                  Faster tax deductions.
                </li>
                <li style={{ fontWeight: 300, fontSize: "1.2rem" }}>
                  Immediate improvements in cash flow.
                </li>
                <li style={{ fontWeight: 300, fontSize: "1.2rem" }}>
                  Opportunities for reinvestment in property upgrades or new
                  investments.
                </li>
              </ul>
            </div>
          </div>
          <div className="tool-card">
            <Image
              src={`${process.env.PUBLIC_URL}/images/analyze.png`}
              alt="Analyze STR Markets"
            />
            <div className="tool-content">
              <Header
                style={{
                  textAlign: "left",
                  paddingLeft: 0,
                  fontSize: "1.5rem",
                  fontWeight: 600
                }}
                size={3}
              >
                Tax Savings for Self-Managers
              </Header>
              <Text
                style={{
                  fontSize: "1.2rem"
                }}
              >
                STR owners who actively manage their properties stand to gain
                the most from cost segregation{" "}
                {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}. By
                accelerating depreciation, you can unlock significant savings
                on:
              </Text>
              <ul>
                <li style={{ fontWeight: 300, fontSize: "1.2rem" }}>
                  Guest-facing furnishings and amenities.
                </li>
                <li style={{ fontWeight: 300, fontSize: "1.2rem" }}>
                  Outdoor upgrades such as patios, fire pits, or landscaping.
                </li>
                <li style={{ fontWeight: 300, fontSize: "1.2rem" }}>
                  Technology investments like smart locks, security systems, and
                  custom lighting.
                </li>
              </ul>
            </div>
          </div>
          <div className="tool-card">
            <Image
              src={`${process.env.PUBLIC_URL}/images/buy.png`}
              alt="Browse For-Sale Airbnb Listings"
            />
            <div className="tool-content">
              <Header
                style={{
                  textAlign: "left",
                  paddingLeft: 0,
                  fontSize: "1.5rem",
                  fontWeight: 600
                }}
                size={3}
              >
                Requirements for Self-Managers
              </Header>
              <Text style={{ fontSize: "1.2rem" }}>
                To qualify for the tax benefits associated with cost segregation{" "}
                {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}, STR
                owners must meet these material participation criteria:
              </Text>
              <ul>
                <li style={{ fontWeight: 300, fontSize: "1.2rem" }}>
                  100-Hour Rule: You must spend at least 100 hours annually
                  managing the property.
                </li>
                <li style={{ fontWeight: 300, fontSize: "1.2rem" }}>
                  Majority Involvement: You must spend more time managing than
                  any other individual or group.
                </li>
                <li style={{ fontWeight: 300, fontSize: "1.2rem" }}>
                  Active Engagement: Tasks must include guest communication,
                  maintenance, bookings, and more.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <PopupButton
          id="Q3vyi5Mv"
          shareGaInstance="true"
          hidden={{
            ...typeformHiddenFields,
            source_page: "/(cta-bottom-homepage)",
            start_point: "selling"
          }}
          data-id="connect-with-cost-seg-specialist_button"
          className="connect-with-cost-seg-specialist_button_top btn-consult"
          style={{
            flex: mobileSize ? "1 0 100%" : "0 1 auto",
            display: "block",
            background: "var(--linearGradient)",
            margin: "1rem auto"
          }}
        >
          Get Started Now
        </PopupButton>
      </Section>
      <HR />
      <Section>
        <Header size={2}>What Is a Cost Segregation Study?</Header>
        <Header
          size={3}
          style={{ color: "var(--sixth)", marginBottom: "2rem" }}
        >
          Boost Cash Flow and Offset W-2 Income with Accelerated Depreciation{" "}
          {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}
        </Header>
        <Text style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          A cost segregation study is a powerful tax strategy designed to help
          property owners maximize their tax savings by accelerating the
          depreciation of specific property components. For short-term rental
          (STR) investors who own and operate their properties, this strategy
          can significantly reduce taxable income, including your W-2 income,
          and improve cash flow.
        </Text>
        <Text style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          Here’s how it works: instead of depreciating your entire property over
          27.5 or 39 years, a cost segregation study breaks down your property
          into components (like furniture, appliances, and landscaping) that can
          be depreciated over much shorter timeframes—5, 7, or 15 years.
        </Text>
        <Text style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          For STR Investors:
        </Text>
        <ul>
          <li
            style={{
              fontWeight: 300,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            <Text
              style={{
                fontWeight: 400,
                fontSize: mobileSize ? "1.2rem" : "1.4rem"
              }}
            >
              Offset W-2 Income:
            </Text>{" "}
            If you meet the material participation requirements as a
            self-managing STR operator, the depreciation from cost segregation
            can be used to directly offset your earned W-2 income. This is
            particularly beneficial for high-income earners who are looking to
            reduce their taxable income significantly.
          </li>
          <li
            style={{
              fontWeight: 300,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            <Text
              style={{
                fontWeight: 400,
                fontSize: mobileSize ? "1.2rem" : "1.4rem"
              }}
            >
              Increase Cash Flow:
            </Text>{" "}
            The accelerated tax deductions free up cash that can be reinvested
            into property upgrades, debt reduction, or expanding your real
            estate portfolio
          </li>
          <li
            style={{
              fontWeight: 300,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            <Text
              style={{
                fontWeight: 400,
                fontSize: mobileSize ? "1.2rem" : "1.4rem"
              }}
            >
              STR-Specific Benefits:
            </Text>{" "}
            Short-term rentals often feature furniture, fixtures, and
            guest-focused amenities (e.g., outdoor lighting, fire pits, and
            smart locks) that are ideal candidates for accelerated depreciation,
            maximizing your deductions.
          </li>
        </ul>
        <Text style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          Cost segregation is especially impactful for STR investors because of
          the unique operational demands and high-value assets typically
          associated with short-term rentals. By combining active participation
          with this strategy, you can create immediate financial benefits while
          building long-term wealth.
        </Text>
      </Section>
      <Group style={{ background: "var(--lightPurple)" }}>
        <Section style={{ background: "none" }}>
          <Header size={2} style={{ color: "var(--quinary)" }}>
            Engineering-Based Studies vs DIY
          </Header>
          <Text style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
            Cost segregation studies are best conducted by engineers and tax
            professionals{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}. These
            experts provide accurate analyses to identify and reclassify
            components, ensuring IRS compliance and maximizing tax savings.
            While non-engineering options exist, they’re less precise and
            increase the risk of audit. An engineering-based study offers:
          </Text>
          <ul>
            <li
              style={{
                fontWeight: 300,
                fontSize: mobileSize ? "1.2rem" : "1.4rem"
              }}
            >
              Higher accuracy.
            </li>
            <li
              style={{
                fontWeight: 300,
                fontSize: mobileSize ? "1.2rem" : "1.4rem"
              }}
            >
              Greater tax-saving opportunities.
            </li>
            <li
              style={{
                fontWeight: 300,
                fontSize: mobileSize ? "1.2rem" : "1.4rem"
              }}
            >
              Peace of mind from audit protection.
            </li>
          </ul>
          <PopupButton
            id="Q3vyi5Mv"
            shareGaInstance="true"
            hidden={{
              ...typeformHiddenFields,
              source_page: "/(cta-bottom-homepage)",
              start_point: "selling"
            }}
            data-id="connect-with-cost-seg-specialist_button"
            className="connect-with-cost-seg-specialist_button_top btn-consult"
            style={{
              flex: mobileSize ? "1 0 100%" : "0 1 auto",
              display: "block",
              margin: "1rem auto"
            }}
          >
            Get Started Now
          </PopupButton>
        </Section>
      </Group>
      <Section>
        <Header size={2}>Unlocking $36k in Tax Refunds</Header>
        <Header size={3} style={{ color: "var(--quinary)" }}>
          Our Journey of DIY Short-Term Rental Management Success
        </Header>
        <SplitView
          split="2:1"
          style={{
            alignItems: "flex-start",
            marginTop: "2rem",
            marginBottom: "2rem",
            display: mobileSize ? "block" : "grid"
          }}
        >
          <div style={{ padding: "1rem 1rem 1rem 0rem" }}>
            <Text block style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
              When we first ventured into short-term rental management, we
              quickly realized the untapped potential for tax savings through a
              cost segregation study. By identifying and reclassifying assets
              like furniture, landscaping, and appliances, we accelerated
              depreciation and uncovered $36,000 in tax refunds.
            </Text>
            <Text block style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
              This strategic approach allowed us to reinvest in our property,
              upgrade amenities for guests, and boost our overall cash flow.
            </Text>
          </div>
          <div style={{ padding: "1rem 0" }}>
            <Image
              src={`${process.env.PUBLIC_URL}/images/breck-house.png`}
              alt="Self Manager Your Airbnb Rental Property to Maximize Tax Savings"
              style={{
                width: "100%",
                height: "auto"
              }}
            />
          </div>
        </SplitView>
        <Text style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          What’s more, taking charge of our own rental operations and partnering
          with the right experts gave us full control over the process. The
          results speak for themselves—not just in our tax savings, but in the
          increased value and efficiency of our rental business. If you’re a
          property owner looking to maximize your earnings, cost segregation
          might just be the game-changer you need.
        </Text>
        <Button
          onClick={() =>
            handleNavigation(
              "//blog/how-to-maximize-tax-savings-with-cost-segregation-for-short-term-rentals",
              "section_our_journey_cost_segregation"
            )
          }
          style={{
            width: "100%",
            padding: "0.5rem 1rem",
            color: "var(--white)",
            fontSize: "1.2rem",
            justifyContent: "center",
            fontWeight: 500,
            textAlign: "center",
            background: "var(--linearGradient)",
            margin: "1rem auto",
            maxWidth: "300px"
          }}
        >
          Read the Full Story
        </Button>
      </Section>
      <Section
        style={{
          boxShadow: "none",
          background: "var(--sixth)",
          maxWidth: "100%",
          padding: "4rem 0"
        }}
      >
        <div className="get-started-container">
          <div className="get-started-content">
            <h2>Ready to Maximize Your Tax Savings?</h2>
            <p>
              Turn Depreciation into Airbnb Investment Tax Deductions{" "}
              {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}.
            </p>
            <div className="get-started-buttons">
              {/* <Button onClick={() => history.push("/buying-an-airbnb")}>
                I am buying an Airbnb
              </Button> */}
              <PopupButton
                id="Q3vyi5Mv"
                shareGaInstance="true"
                hidden={{
                  ...typeformHiddenFields,
                  source_page: "/(cta-bottom-homepage)",
                  start_point: "selling"
                }}
                data-id="connect-with-cost-seg-specialist_button"
                className="connect-with-cost-seg-specialist_button_top btn-consult"
                style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
              >
                Connect With a Cost Segregation Expert
              </PopupButton>
            </div>
          </div>
          <div className="get-started-image">
            <Image
              src={`${process.env.PUBLIC_URL}/images/house-with-palm-trees.svg`}
              alt="House with palm trees illustration"
            />
          </div>
        </div>
      </Section>
      <Section>
        <Header size={2}>What Assets Qualify for Cost Segregation?</Header>
        <Text style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          Cost segregation allows STR owners to accelerate depreciation on
          specific components. Here’s what typically qualifies:
        </Text>
        <ul>
          <li
            style={{
              fontWeight: 300,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            <Text
              style={{
                fontWeight: 400,
                fontSize: mobileSize ? "1.2rem" : "1.4rem"
              }}
            >
              Interior Features:
            </Text>{" "}
            Furniture, appliances, and carpeting (e.g., beds, refrigerators,
            window treatments).
          </li>
          <li
            style={{
              fontWeight: 300,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            <Text
              style={{
                fontWeight: 400,
                fontSize: mobileSize ? "1.2rem" : "1.4rem"
              }}
            >
              Exterior Improvements:
            </Text>{" "}
            Landscaping, patios, fire pits, decks, and outdoor lighting.
          </li>
          <li
            style={{
              fontWeight: 300,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            <Text
              style={{
                fontWeight: 400,
                fontSize: mobileSize ? "1.2rem" : "1.4rem"
              }}
            >
              Technology:
            </Text>{" "}
            Smart locks, security systems, and custom lighting.
          </li>
          <li
            style={{
              fontWeight: 300,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            <Text
              style={{
                fontWeight: 400,
                fontSize: mobileSize ? "1.2rem" : "1.4rem"
              }}
            >
              Specialized Components:
            </Text>{" "}
            Plumbing for spa tubs, HVAC systems, or smart locks.
          </li>
          <li
            style={{
              fontWeight: 300,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            <Text
              style={{
                fontWeight: 400,
                fontSize: mobileSize ? "1.2rem" : "1.4rem"
              }}
            >
              Miscellaneous Assets:
            </Text>{" "}
            Security systems, signage, or parking lot upgrades.
          </li>
        </ul>
        <Text style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          By identifying and reclassifying these assets, you can unlock hidden
          savings and reinvest in growing your portfolio.
        </Text>
      </Section>
      <HR />
      <Section>
        <Header size={2}>Strategic Considerations</Header>
        <Header size={3} style={{ color: "var(--sixth)" }}>
          Tailoring Cost Segregation to Your Investment’s Unique Attributes
        </Header>
        <Text block style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          <Text
            style={{
              fontWeight: 400,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            1. Self-Management Requirement:
          </Text>{" "}
          To qualify for the tax benefits of cost segregation, you must actively
          manage your property. This includes meeting the material participation
          criteria, such as:
          <ul>
            <li style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
              Spending at least 100 hours annually on property management.
            </li>
            <li style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
              Being the primary manager, dedicating more time than any other
              individual or group.
            </li>
            <li style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
              Handling key operational tasks, like guest communication,
              maintenance, and bookings.
            </li>
          </ul>
        </Text>
        <Text block style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          <Text
            style={{
              fontWeight: 400,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            2. Short-Term Rental Specific:
          </Text>{" "}
          A property with an average guest stay of seven days or less typically
          qualifies under this exception. If you use the property personally
          (for vacations, etc.), be aware of the 14-day guideline. Surpassing 14
          days of personal use can restrict or negate your ability to take the
          larger deductions. Even a few personal days can reduce what you’re
          allowed to claim. You need to track (a) your hours and (b) others’
          hours—especially if you’re using the 100-hour “more than anyone else”
          test. Good documentation wins audits.
        </Text>
        <Text block style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          <Text
            style={{
              fontWeight: 400,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            3. Upfront Costs:
          </Text>{" "}
          Professional cost segregation studies can involve a significant
          initial investment. However, the potential tax savings—especially for
          high-value properties—usually outweigh these costs.
        </Text>
        <Text block style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          <Text
            style={{
              fontWeight: 400,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            4. Property Value:
          </Text>{" "}
          Properties with higher values or substantial renovations typically see
          the greatest return on investment from cost segregation studies.
        </Text>
        <Text block style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          <Text
            style={{
              fontWeight: 400,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            5. Tax Bracket Impact:
          </Text>{" "}
          Your tax bracket significantly influences your savings. For example,
          if you’re in a 37% tax bracket, $100,000 in depreciation could
          translate to $37,000 in additional cash flow.
        </Text>
        <Text block style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          <Text
            style={{
              fontWeight: 400,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            6. Potential Audit Risks:
          </Text>{" "}
          Non-engineering studies increase the likelihood of an IRS audit.
          Hiring experienced professionals ensures compliance with guidelines
          and proper documentation to minimize risks.
        </Text>
        <Text block style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
          <Text
            style={{
              fontWeight: 400,
              fontSize: mobileSize ? "1.2rem" : "1.4rem"
            }}
          >
            7. Professional Involvement:
          </Text>{" "}
          Engineering-based studies provide greater accuracy and uncover more
          savings. Engaging professionals guarantees you’ll maximize your
          benefits while adhering to IRS standards.
        </Text>
        <PopupButton
          id="Q3vyi5Mv"
          hidden={typeformHiddenFields}
          shareGaInstance="true"
          data-id="connect-with-cost-seg-specialist_button"
          className="connect-with-cost-seg-specialist_button"
          style={{
            // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
            backgroundColor: mobileSize ? "var(--quinary)" : "var(--quinary)",
            textTransform: "uppercase",
            color: "var(--white)",
            padding: "1rem",
            border: 0,
            fontWeight: 400,
            width: mobileSize ? "100%" : "initial",
            textDecoration: "none",
            display: "block",
            fontSize: "1rem",
            cursor: "pointer",
            textAlign: "center",
            boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
            borderRadius: "0.25rem",
            // display: mobileSize ? "block" : "initial",
            margin: mobileSize ? "1rem auto" : "1rem auto"
          }}
        >
          Connect With a Cost Segregation Expert
        </PopupButton>
      </Section>
      <FAQSection>
        <Header size={2}>Frequently Asked Questions</Header>
        <div className="faq-item">
          <Header size={2}>
            🤝 When should I consider doing a cost segregation study{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}?
          </Header>
          <Text style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
            A cost segregation study is ideal for STR owners and real estate
            investors looking to maximize tax savings, especially if you’ve
            recently acquired, renovated, or own high-value properties{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}. You .
          </Text>
        </div>
        <div className="faq-item">
          <Header size={2}>
            Can I do a cost segregation study if I use a property management
            company?
          </Header>
          <Text style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
            No, to fully qualify for the tax benefits, you must meet the
            material participation requirements. These rules generally exclude
            those who rely on property management companies, as active
            self-management is required to claim the benefits.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={2}>
            🏠 Is the cost segregation tax benefit getting phased out?
          </Header>
          <Text style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
            Yes, bonus depreciation has started to phase out. It dropped from
            100% in prior years to 60% in 2024 and is scheduled to decrease to
            40% in 2025 under current law.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={2}>
            📣 Is there a chance that 100% bonus depreciation will be
            reinstated?
          </Header>
          <Text style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
            There is potential for legislative changes. Lawmakers have discussed
            reinstating or extending 100% bonus depreciation. Keep an eye on tax
            news as 2025 approaches, as updates may impact your planning.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={2}>Does my CPA handle cost segregation studies?</Header>
          <Text style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
            Most CPAs do not perform cost segregation studies directly. These
            studies often require specialized engineering expertise. However, we
            can connect you with professionals who collaborate closely with CPAs
            to ensure the study aligns seamlessly with your tax filings.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            📣 What are the risks of a cost segregation study
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}?
          </Header>
          <Text block style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
            The primary risk is a potential IRS audit, particularly if the study
            lacks precision or proper documentation. Working with qualified
            professionals ensures compliance with IRS regulations and minimizes
            audit risks.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            📣 Do you have a Real Estate focused CPA who also understands cost
            segregation{" "}
            {marketPathParam && ` in ${LiveMarkets[marketPathParam]}`}?
          </Header>
          <Text block style={{ fontSize: mobileSize ? "1.2rem" : "1.4rem" }}>
            We do! We recommend{" "}
            <a
              href="https://taxstrategy365.com/apply1?am_id=ashleydurmo"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "var(--fourth)", textDecoration: "underline" }}
            >
              TaxStrategy365
            </a>{" "}
            - they specialize in assisting Real Estate Tax planning and
            strategizing.
          </Text>
        </div>
        <PopupButton
          id="Q3vyi5Mv"
          hidden={typeformHiddenFields}
          shareGaInstance="true"
          data-id="connect-with-cost-seg-specialist_button"
          className="connect-with-cost-seg-specialist_button"
          style={{
            // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
            backgroundColor: mobileSize ? "var(--quinary)" : "var(--quinary)",
            textTransform: "uppercase",
            color: "var(--white)",
            padding: "1rem",
            border: 0,
            fontWeight: 400,
            width: mobileSize ? "100%" : "initial",
            textDecoration: "none",
            display: "block",
            fontSize: "1rem",
            cursor: "pointer",
            textAlign: "center",
            boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
            borderRadius: "0.25rem",
            // display: mobileSize ? "block" : "initial",
            margin: mobileSize ? "1rem auto" : "1rem auto"
          }}
        >
          Connect With a Cost Segregation Expert
        </PopupButton>
      </FAQSection>

      <DisclaimerSection />
    </>
  );
};

export default STRLenders;
